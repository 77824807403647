import React, { useEffect, useState, useRef } from "react";
import { Route, Link, useLocation, Switch } from "react-router-dom";
import Fade from "react-reveal/Fade";

import ercan1 from "../src/img/ercan2.png";
import Bio from "../src/components/bio";
import Artikel from "../src/components/artikel";
import Footer from "../src/components/footer";
import Videos from "../src/components/videos";
import Artikel0 from "../src/components/artikel/artikel0";
import Artikel1 from "../src/components/artikel/artikel1";
import Artikel2 from "../src/components/artikel/artikel2";

import "./App.css";

function App() {
    const [lang, setLang] = useState(0);
    const [cookie, setCookie] = useState(false);
    const [menuList, setMenuList] = useState();
    const location = useLocation();
    const locStyle = { textDecoration: "underline" };

    const de = useRef(null);
    const tr = useRef(null);

    useEffect(() => {
        if (!lang) {
            if (location.pathname === "/artikel") {
                setMenuList(
                    <div className="menu-list">
                        <Link to="/artikel" style={locStyle}>
                            artikel
                        </Link>
                        <Link to="/videos">videos</Link>
                    </div>
                );
            } else if (location.pathname.startsWith("/videos")) {
                setMenuList(
                    <div className="menu-list">
                        <Link to="/artikel">artikel</Link>
                        <Link to="/videos" style={locStyle}>
                            videos
                        </Link>
                    </div>
                );
            } else {
                setMenuList(
                    <div className="menu-list">
                        <Link to="/artikel">artikel</Link>
                        <Link to="/videos">videos</Link>
                    </div>
                );
            }

            de.current.style.fontStyle = "italic";
            de.current.style.textDecoration = "underline";
            tr.current.style.fontStyle = "normal";
            tr.current.style.textDecoration = "none";
        } else {
            if (location.pathname === "/artikel") {
                setMenuList(
                    <div className="menu-list">
                        <Link to="/artikel" style={locStyle}>
                            yazılar
                        </Link>
                        <Link to="/videos">videolar</Link>
                    </div>
                );
            } else if (location.pathname.startsWith("/videos")) {
                setMenuList(
                    <div className="menu-list">
                        <Link to="/artikel">yazılar</Link>
                        <Link to="/videos" style={locStyle}>
                            videolar
                        </Link>
                    </div>
                );
            } else {
                setMenuList(
                    <div className="menu-list">
                        <Link to="/artikel">yazılar</Link>
                        <Link to="/videos">videolar</Link>
                    </div>
                );
            }

            tr.current.style.fontStyle = "italic";
            tr.current.style.textDecoration = "underline";
            de.current.style.fontStyle = "normal";
            de.current.style.textDecoration = "none";
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang, location, cookie]);

    return (
        <div className="App">
            {!cookie && (
                <div className="cookie">
                    <p>
                        Diese Website verwendet Cookies.{" "}
                        <span
                            onClick={(e) => {
                                setCookie(true);
                            }}
                        >
                            Klicken Sie hier
                        </span>
                        , um zu akzeptieren.
                    </p>
                </div>
            )}
            <Fade left cascade duration={1000}>
                <div className="menu">
                    <Link to="/">
                        <h2>Ercan Yaşaroğlu</h2>
                    </Link>

                    <div className="menu-list">{menuList}</div>
                    <div className="lang">
                        <p
                            ref={de}
                            onClick={() => {
                                setLang(0);
                            }}
                        >
                            de
                        </p>
                        <p>/</p>
                        <p
                            ref={tr}
                            onClick={() => {
                                setLang(1);
                            }}
                        >
                            tr
                        </p>
                    </div>
                    <div id="ercan-img">
                        <img id="ercan1" src={ercan1} alt=".."></img>
                    </div>
                </div>
            </Fade>
            <div className="content">
                <Switch>
                    <Route exact path="/">
                        <Bio lang={lang}></Bio>
                    </Route>
                    <Route exact path="/artikel">
                        <Artikel lang={lang}></Artikel>
                    </Route>
                    <Route exact path="/artikel/0">
                        <Artikel0></Artikel0>
                    </Route>
                    <Route exact path="/artikel/1">
                        <Artikel1></Artikel1>
                    </Route>
                    <Route exact path="/artikel/2">
                        <Artikel2></Artikel2>
                    </Route>
                    <Route path="/videos">
                        <Videos lang={lang}></Videos>
                    </Route>
                </Switch>
            </div>
            <Footer></Footer>
        </div>
    );
}

export default App;
