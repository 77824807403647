import React from "react";
import Fade from "react-reveal/Fade";

function Artikel0() {
    return (
        <div className="artikel">
            <Fade top duration={2000}>
                <h2>Die Situation der ImmigrantInnen in Deutschland</h2>
                <div className="text">
                    <p>
                        Bevor wir auf die Gründung von "Antifasist Genclik"
                        eingehen, wollen wir erst einmal die Lebenssituation der
                        in Deutschland lebenden Immigrantinnen kurz aufreißen.
                        Die Immigrantinnen wurden in den 60´er Jahren von
                        damaligen Politikern und Firmen in die Bundesrepublik
                        angeworben.
                    </p>
                    <p>
                        Die meisten von ihnen kamen aus ländlichen Gebieten.
                        Diese Menschen, denen das Leben in den Großstädten ihres
                        Heimatlandes schon fremd war, finden sich plötzlich in
                        einem technologisch hochentwickeltes Land wieder. Es ist
                        für sie ein Land, dessen Sprache sie nicht kennen,
                        dessen Lebensweise, Sitten und Gebräuche gänzlich
                        verschieden sind. Sie haben Angst, in der neuen Kultur
                        aufzugehen und sich zu entfremden. Obwohl sich ihr
                        Aufenthalt in der Bundesrepublik immer mehr in die Länge
                        gezogen hat, haben sie sich eingeredet, daß sie nur
                        vorübergehend hier arbeiten und früher oder später in
                        die Heimat zurückkehren werden.
                    </p>
                    <p>
                        Man hat ihnen auf der anderen Seite nur soviel
                        Möglichkeiten eingeräumt, Deutsch zu lernen, wie sie zum
                        Arbeiten und Einkaufen benötigt haben. Wenn es einige
                        Familien aus der Heimat gibt, die sie besuchen können,
                        so teilen sie mit ihnen ihr Heimweh und versuchen es
                        gemeinsam zu lindern. Auch wenn sie mitten in der Stadt
                        wohnen, leben sie doch außerhalb oder am Rande der für
                        sie neuen Kultur. Sie ziehen in die alten, von den
                        Europäern nicht mehr bewohnten Stadtviertel und bilden
                        dort eine Solidargemeinschaft, in der sie ihre alten
                        Lebensweisen, ihre Kultur, ihre Kleidung, ihr Essen,
                        ihre Gewohnheiten etc. fortführen. Sie treffen sich in
                        Cafés, die sie selbst bewirtschaften, schicken Ihre
                        Kinder zum Koran-Kurs und beten gemeinsam in den
                        Moscheen.
                    </p>
                    <p>
                        Da sie von der neuen Gesellschaft nur als "Gastarbeiter"
                        wahrgenommen werden und ihnen nicht das Gefühl
                        vermittelt wird, daß sie ein Teil von ihr sind, fehlt
                        ihnen die Flexibilität, um sich der neuen Kultur zu
                        öffnen. Sie klammern sich um so heftiger an Ihre eigenen
                        Identität. In ihrem Unterbewußtsein fürchten sie sich in
                        der neuen Kultur aufzugehen und sich zu verlieren,
                        deshalb klammern sie sich an ihre Traditionen und
                        besonders an ihre Religion, wie ein Schutzpanzer.
                        Außerdem müssen sie sich seit ca.30 Jahren mit dem
                        Diskriminierungen und Ausgrenzungen der deutschen
                        Gesellschaft auseinandersetzen. Sie spüren deutlich die
                        Ablehnung und können sich einerseits nicht wehren, weil
                        sie die Sprache nicht beherrschen und andererseits
                        wollen sich nicht wehren, weil sie ihren Aufenthalt in
                        der BRD nur als vorübergehen betrachten. Das führt zu
                        einer Passivität, die sich abgesehen von dem Geld des
                        BRD-Systems und des Arbeitssystems, gegen allen
                        kulturellen Einflüssen verschließen. Diese Haltung ist
                        eine starke Verteidigung und entspricht einem Instinkt
                        der Selbstbehauptung.
                    </p>
                    <p>
                        Diese unsoziale Haltung, die sich seit ihrer Immigration
                        in die BRD abspielt, verursacht bei ihnen pyschologisch
                        bedingte Krankheiten und unnatürliche Verhaltensweisen.{" "}
                    </p>
                    <h4>Die Kinder und Jugendlichen</h4>
                    <p>
                        Die "Identitätskrise" eines Jugendlichen, der in der
                        Bundesrepublik aufwächst, bricht heftiger aus. Da ist
                        einerseits die "konservativ" und traditionell lebende
                        Familie und andererseits eine Umgebung in der total
                        verschiedene Weltanschauungen und jede menge verlockende
                        Möglichkeiten, angeboten werden. Der Jugendliche möchte
                        in diese Umgebung hineingehen, von Ihren Möglichkeiten
                        profitieren und Mitglied werden.{" "}
                    </p>
                    <p>
                        Die "Identitätskrise" eines Jugendlichen, der in der
                        Bundesrepublik aufwächst, bricht heftiger aus. Da ist
                        einerseits die "konservativ" und traditionell lebende
                        Familie und andererseits eine Umgebung in der total
                        verschiedene Weltanschauungen und jede menge verlockende
                        Möglichkeiten, angeboten werden. Der Jugendliche möchte
                        in diese Umgebung hineingehen, von Ihren Möglichkeiten
                        profitieren und Mitglied werden. An dieser Stelle kommt
                        es zum offenen Konflikt mit der Familie. Die Eltern
                        spüren die Entfemdung der Kinder von ihnen. Die Kinder
                        fangen an europäische Musik zu hören, sich wie die
                        deutschen FreundInnen zu bekleiden und ihre Sprache
                        immer weniger zu beherrschen. Die Eltern sind dieser
                        Entwicklung ihrer Kinder hoffnungslos ausgesetzt, weil
                        sie in ihrer Kultur erstarren und ihre Flexsibilität
                        verlieren. Daher versuchen sie oft mit Einschüchterungen
                        und strenge Kontrollen, die Kinder von der Entfremdung
                        zu ihen selbst zu verhindern. Aber sie können die
                        Jugendlichen weder beeinflussen noch beaufsichtigen. Je
                        stärker der Jugendliche unter Druck gesetzt wird, um so
                        größer ist der Widerstand gegen die Eltern. Auf der
                        anderen und Seite aber, gehen die Sticheleien,
                        Verachtungen, Ausgrenzungen, Diskriminierungen und der
                        Rassismus der Umgebung und Institutionen weiter. Die
                        Jugendlichen wollen sich vom Elternhaus loslösen, aber
                        allen Bemühungen zum Trotz, werden sie er von der
                        Umgebung nicht aufgenommen. Sie versuchen dann durch
                        sogenannte neben Eingänge, sich einen Zugang zu ihrere
                        Umwelt zu verschaffen. Da ihnen meistens die Schultüren
                        verschlossen sind, versuchen sie andere Eingänge zu
                        finden. Sie suchen so z.B. ihr Glück in Sportarten, wie
                        Judo, Karate, Boxen und Fußball.... Das dient nicht nur
                        zum Erfolg sondern ist auch eine Verteidigung gegenüber
                        der feindlichen Umgebung.{" "}
                    </p>
                    <p>
                        Einige versuchen dadurch akzeptiert zu werden, daß sie
                        ihre Begabung im Breakdance, Disco-dance usw. unter
                        Beweis stellen. Da sie sich nur in ihrem Freundeskreis
                        frei und sicher fühlen, solidarisieren sie sich mit ihen
                        und gehen eine Schicksalsgemeinschaft ein. Sie suchen
                        gemeinsam ihre Identität zwischen der Familie und der
                        Umgebung. So entwickeln sie für sich selbst eine
                        Identität, die eine Mischkultur aus beiden ist, an die
                        sie sich fest halten können. Die 2. bzw.3.Generation
                        also kämpft unter viel härteren Bedingungen, als die 1.
                        Generation. Sie haben zwar keine materiellen Sorgen,
                        dafür aber sind sie stärker mit einem Identitätschaos
                        konfrontiert. Sie erleben die Pubertätsphase auf einer
                        noch stürmischen Weise und finden selten einen Ausweg.
                        Sie leiden ferner unter dem Bruch mit der Familie und
                        leben zugleich mit dem Dilemma, in keines der
                        Gesellschaften akzeptiert zu werden. Jeder fünfte
                        Jugendlicher in Berlin unter 25 Jahren ist Immigrant.{" "}
                    </p>
                    <p>
                        Obwohl sie größtenteils in Berlin geboren und
                        aufgewachsen sind, unterscheiden sie sich deutlich in
                        Bezug auf ihre Sozialisation von Ihren deutschen
                        Freunden. Das liegt neben den oben angeführten Gründen,
                        auch an ihrem rechtlichen "Ausländerstatus" und der
                        Tatsache, daß dieser Status nicht der Bi-Kulturellität
                        dieser Jugendlichen gerrecht wird. Die Jugendlichen
                        haben in Gebieten, wie Kreuzberg und Wedding sehr wenig
                        Chancen, eine gute schulische Ausbildung zu erlernen und
                        in den "Ausländerklassen" lernen sie kaum die deutsche
                        Sprache zu beherrschen. Damit werden ihnen von
                        vornherein Schranken gestellt, daß eine schulische
                        Kariere verhindert und schlechte Voraussetzungen für den
                        späteren Beruf schafft. Nicht selten wird dieses Dilemma
                        durch die rassistische Einstellung der ArbeitgeberInnen
                        und LehrerInnen verstärkt.
                    </p>
                    <h4>
                        Die Situation der Immigrantinnen aus der 2. und 3.
                        Generation
                    </h4>
                    <p>
                        Die Mädchen haben eine viel schwierigere Sozialisation
                        als die Jungs. Sie lernen auf der einen Seite die Rolle
                        der Frau in der traditionellen kurdischen bzw.
                        türkischen Gesellschaft in ihren Familien, auf der
                        anderen Seite werden sie mit der Rolle der deutschen
                        Frauen in den Schulen bzw. Arbeitstellen konfrontiert.{" "}
                    </p>
                    <p>
                        In der Familie werden ihrer Freiheiten stark eingeengt.
                        Sie müssen im Haushalt helfen, auf ihre Geschwister
                        aufpassen, mit den Eltern die Verwandten besuchen usw.
                        Ihre ganze Freizeit wird von der Familie bestimmt. Das
                        Verhalten der Eltern gegenüber der Mädchen wird bestimmt
                        durch die kleine soziale Gemeinschaft der Eltern. Vor
                        jeder noch so kleinen Handlungsmöglichkeit der Mädchen
                        wird zunächst fantasiert, was denn die anderen Leute
                        darüber denken, was passieren würde, wenn die Tochter
                        z.B. einmal ausgeht! Die Eltern haben in ihrer eigenen
                        sozialen Gemeinschaft, eigene Werte und Normen, die
                        obwohl sie in einer anderen Gesellschaft leben, nicht
                        angetastet haben möchten und nahezu tabuisiert sind.{" "}
                    </p>
                    <p>
                        Neben klar definierten Rollenzuschreibungen und Tabus,
                        gilt das Verbot der sexuellen Beziehung als oberstes
                        Gebot bis zur Heirat. Die Eltern spielen in dieser
                        Tragikomödie, "die Demokratie wächst aus der Familie
                        heraus", die Rolle der ausführenden und kontrollierenden
                        Gewalt. Diese Gewaltenteilung bei der den Mädchen nicht
                        einmal die Rolle des Volkes zugesprochen wird, sondern
                        die der Gefangeninnen, gilt bis zu dem Tag, an dem die
                        Tochter das Elternhaus verläßt und das Bündniss der Ehe
                        eingeht. Die Eltern sind nun unendlich stolz, da sie
                        ihre "heilige Aufgabe" erfüllt haben.{" "}
                    </p>
                    <p>
                        Welchen Einfluß üben diese Tatsachen jedoch auf das
                        Leben der Mädchen außerhalb der Familie aus? Für die
                        Mädchen sind dieseTabus wie z.B. Ehre und Stolz, die nur
                        sehr schwer zu durchbrechen sind, ein großes Hindernis,
                        um ihre eigene Normen und Werte zu entwickeln. Hier ist
                        die Rede von der 2. Generation von Jugendlichen, die
                        durch die Schule, die Ausbildung, dem Arbeitsplatz u.ä.
                        mit deutschen Jugendlichen zusammenkommen. Es stellt
                        sich in diesem Zusammenhang die Integrationsproblematik
                        in den Vordergrund, wobei man meinen könnte, daß sich
                        die Frage der Integration der Mädchen von selbst löst,
                        weil ja die Eltern die Rolle des Beschützers einnehmen
                        und ihre Töchter von jeder nicht-familiären Gesellschaft
                        ausschließen? Die Verpflichtung der Eltern ihre Kinder
                        beschützen zu müssen, zeigt einerseits, daß den Töchtern
                        gegenüber kein Vertrauen herrscht und andrerseits ist
                        dieses Mißtrauen ein Beweis dafür, daß die Eltern sich
                        über die falsche Erziehung bewußt sein müssen, aber als
                        ein "Teil ihrer sozialen Gemeinschaft", dem sie sich
                        unterordnen, wollen und können sie ihre Fehler nicht
                        einsehen. Sie bleiben also weiterhin von ihren starren
                        Lebensvorstellungen abhängig und die Mädchen versuchen
                        unter "doppel-moralischen" Normen ihre Identität zu
                        finden. Wir haben uns in der AG das zusätzliche Ziel
                        gesetzt, Frauen und Männer in ihrem Identitätsprozeß zu
                        unterstützen und haben daher angefangen in "Antifasist
                        Haberbülteni" (Anti-Faschistisches Info Blatt) Artikel
                        über Frauen und Sexismus zu schreiben. Es ist uns leider
                        nicht gelungen, das sexistische Verhalten von einigen
                        Leuten aus unserer Gruppe zu verringen. Eine
                        Selbstorganisierung der Frauen, die sich intensiver mit
                        dem Sexismus und der Notwendigkeit der
                        Selbstorganisierung der Frauen auseinandersetzt, ist
                        leider aus unsere Gruppe herraus, nicht zustande
                        gekommen.
                    </p>
                    <p>
                        Die ImmigrantInnen sollten ihr hinnehmerisches und
                        passives Verhalten ändern und durch die AG
                        selbstbewußter agieren, um so die deutsche
                        "Ausländer-Politik" zum Scheitern zu bringen. Damit
                        würden wir den "deutschen Herren" eine große
                        Überraschung bereiten, aber leider ist es uns nicht
                        gelungen.{" "}
                    </p>
                    <p>
                        Diese Art der Bewußtseinswerdungsprozeß der MigrantInnen
                        ist eine Voraussetzung zur einer gesunden Intergration,
                        wobei unter Integration keine Einbahnstraße zu verstehen
                        ist. Intergration bedeutet vielmehr ein gegenseitiger
                        und vielfältiger Austausch zwischen den verschiedenen
                        sozialen Gruppen und Gesellschaften unter gleichen
                        rechtlichen Bedinungen.
                    </p>
                    <p>
                        Die offizielle deutsche Integrationspolitik versteht
                        jedoch nur die Hälfte von dem eigentlichen Begriff. Wir
                        wollten den Politikern unsere Sicht von Integration
                        deutlich machen und ihnen zeigen, daß ihr Verständnis
                        von Intergration rassistisch ist und daher so nicht
                        akzeptiert werden kann. Die deutsche Gesellschaft sollte
                        endlich von ihrer ich-bezogenen Lebensart herunterkommen
                        und eine Sensibilität für die immer schlechter werdenden
                        Lebensbedingungen entwickeln. Sie sollte die ganze
                        Schuld nicht bei ImmigrantInnen suchen und sie zu
                        Sündenböcke machen , wie es die Praxis der Politiker
                        ist, sondern endlich einsehen, daß die Ursache der
                        schechter werdenden Lebensbedinungen einzig und allein
                        auf die falsche Politik zurückzuführen ist. Die
                        ImmigrantInnen müssen als gleichberechtigte
                        MitbürgerInnen anerkannt werden und mit den gleichen
                        Rechten ausgestattet werden, um so unter gleichen
                        Voraussetzungen, auf einer viel gleichberechtigten Ebene
                        sich für gemeinsame Forderungen einsetzen und ihre
                        Interessen vertreten zu können.{" "}
                        <i>Das haben die Deutschen anzuerkennen.</i>
                    </p>
                    <p>
                        Seit ca. 30 Jahren jedoch, können die Interessen von uns
                        ImmigrantInnen im Parlament nicht wahrgenommen werden.
                        Das wurde und wird weiterhin von den regierenden
                        Parteien verhindert. Das war eine der wichtigsten
                        Ursachen für die Grüdung der AG. Die AG wollte ein
                        Sprachrohr für die Interessen der MigrantInnen sein und
                        ihre Teilhaberecht an dem gesellschaftlichen Prozeß
                        geltend machen.{" "}
                    </p>
                    <h4>
                        Die Beziehung der deutschen Gesellschaft zur der
                        Immigration!
                    </h4>
                    <p>
                        In den 60´er Jahren, als die ImmigrantInnen von der
                        Bundes-Republik angeworben wurden und nach Deutschland
                        kamen, wurde der deutschen Bevölkerung der Haupt-Gedanke
                        impliziert, daß die ImmigrantInnen nur "Gastarbeiter"
                        seien, die, sobald sich die wirtschaftliche Lage in
                        Deutschland stabilisieren würde, in ihre Herkunftsländer
                        zurückkehren würden. Aus diesem Verständnis heraus, ist
                        auch der Begriff "Gastarbeiter" zu verstehen, der bis
                        Mitte der 70´er Jahre für die Bezeichnung von
                        Immigrantinnen offiziell benutzt wurde und heute immer
                        noch in einigen Kreisen gebräuchlich ist. Die deutsche
                        Bevölkerung hat sich hier von den Medien und Politikern
                        beeinflussen lassen. Sie haben ihnen diese Geschichten
                        abgekauft, vielleicht auch gerade deshalb, weil diese
                        Lügen ihnen ganz gelegen kamen.
                    </p>
                    <p>
                        Ein wesentlicher Grund für die Arbeiteranwerbung aus dem
                        Ausland, lag in dem Machtverhältniss der Unternehmer
                        gegenüber den Beschäftigten. Obwohl es 1960 ca. eine
                        Million Arbeitslose gab, hat man nicht versucht, dieses
                        Arbeitsreservoir auszuschöpfen, sondern es wurde
                        stattdessen für billige Arbeitskräfte aus dem Ausland
                        geworben. Damit wollte man eine Verstärkung der Position
                        der Arbeitnehmer gegenüber den Arbeitgebern
                        entgegenkommen, um so zugleich den Einfluß der "möchte
                        gern" demokratischen Kräfte wie die SPD oder DGB in
                        Zaume halten zu können. Nach der Logik des
                        "Gast"arbeiters, stellte man sich die ImmigrantInnen als
                        Produktionsmaschinen vor, die man zurückgeben konnte
                        oder durch andere ersetzen ließ, wenn man sie nicht mehr
                        brauchte. Aber die ImmigrantInnen sind nun mal keine
                        Maschinen sondern Menschen. Sie hatten ihre Familien in
                        ihren Herkunftsländern zurückgelassen und holten sie
                        nach, sobald sie sich in ihre neue Umgebung eingelebt
                        hatten. Diese Entwicklung kam nicht in den Geschichten
                        der Medien und Politiker vor und die deutsche
                        Bevölkerung zeigte sich überrascht von ihr. Mit dem
                        Ausbruch der Ölkrise 1972 jedoch, fing man an sich
                        ernsthafte Gedanken über die Rückkehr der ImmigrantInnen
                        zu machen. Die Medien, besonders die Boulevardpresse,
                        wie die BILD oder BZ des Springer-Verlags sorgten stark
                        für Vorurteile gegen die ImmigrantInnen, was wiederrum
                        zu immer wachsenden Konflikten zwischen der deutschen
                        Bevölkerung und den ImmigrantInnen führte. Das entsprach
                        natürlich den Kalkulationen der Politiker und war damit
                        ein Erfolg für die Medien. Viele Deutsche trauten sich
                        aus der Erfahrung mit ihrer Vergangenheit nicht, ihre
                        Ausländerfeindlichkeit bis 1980 offen zu zeigen. Ab 1980
                        haben sie angefangen, auf die Berliner Mauer "AUSLÄNDER
                        RAUS" bzw. "TÜRKEN RAUS" zu schreiben. Die Regierung
                        fing zwischen 1982 und 1984 an, Pläne über Prämien für
                        die Rückkehrer/Rückkehrwillige zu entwickeln. Die
                        ImmigrantInnen sollten mit diesen Prämien sich
                        "Arbeitsmöglichkeiten" in ihren Herkunftsländern
                        schaffen. Die Regierung wollte sich damit von späteren
                        "Sozialfällen" befreien. Dieser Plan aber fand nur bei
                        einigen wenigen ImmigrantInnen Anklang. Als dieses die
                        breite Masse der Immigrantinnen nicht reizte, wurde die
                        Parole "AUSLÄNDER RAUS" viel heftiger ausgerufen. Aber
                        die Tabus über die deutsche Vergangenheit waren ja noch
                        nicht zerstört. So wurden unter dem Aspekt, daß die
                        Bundesrepublik ein "demokratisches" Land sei, das sich
                        für Menschenrechte einsetzte, politische Häftlinge aus
                        der damaligen DDR freigekauft, von dem ein Teil bei
                        Bedarf dafür mobilisiert wurde, bei rechten und
                        rassistischen Organisationen zu arbeiten.
                        <i>
                            Mit der Zulassung der REPUBLIKANER als Partei zur
                            Wahl 1989 bestand die Möglichkeit, diese Tabus zu
                            zerstören. Die REPUBLIKANER haben bei ihrer
                            Wahlpropaganda offenen Rassismus betrieben. Alle
                            "demokratischen" Kräfte haben eine stillschweigende
                            und passive Haltung eingenommen. Keiner hat sich
                            darüber Gedanken gemacht, wohin die Bundesrepublik
                            steuert. Die REPUBLIKANER wurden salonfähig gemacht
                            - unter dem Motto "In einer Demokratie kann man sich
                            erlauben, alles frei auszusprechen".
                        </i>
                        <i>
                            Für die Unzulänglichkeiten und die Fehler der
                            Regierung hatte man endlich einen offenen
                            Schuldigen, einen Sündenbock gefunden:
                            ImmigrantInnen. Die REPUBLIKANER brachten viele
                            Verhaltensweisen, die vorher Tabu waren, in eine
                            Form von "Normalität". Die Deutschen konnten sich
                            dadurch offene Gedanken über "Deutsch" sein und über
                            die Zukunft von "Deutschland" machen. Nun
                            artikulierten sich auch die anderen Parteien mit den
                            gleichen Argumenten wie die REPUBLIKANER - die Tabus
                            waren verschwunden. Dieser Zustand hat bis zur
                            Maueröffnung angehalten. Danach hat sich die
                            Situation in der Bundesrepublik langsam zum III.
                            Reich entwickelt.
                        </i>
                    </p>
                    <p>
                        Nach dem Fall der Mauer 1989 zeichnete sich ein neues
                        globales Kräfteverhältnis ab und öffnete damit für die
                        BRD eine neue politische Perspektive. Während wir
                        "Linken" noch stark mit der Verarbeitung der sich
                        schnell vollziehenden Entwicklungen beschäftigt waren,
                        fing auf der anderen Seite die "Bananen-Fütterung" der
                        DDR-BürgerInnen an. Sie sangen Freiheitslieder und
                        feierten mit ihren "reichen Brüdern" aus der
                        Bundesrepublik die Einheit.
                    </p>
                    <p>
                        Die ImmigrantInnen feierten zum Teil mit ihnen und zum
                        Teil beobachteten sie stillschweigend die Geschehnisse
                        von außen. Die Politiker versprachen eine glänzende
                        Zukunft für ihre BürgerInnen und die vom glänzenden
                        Anschein der Bundesrepublik verblendeten BürgerInnen der
                        DDR glaubten alles, was man ihnen sagte.
                    </p>
                    <p>
                        So beobachteten sie brav, wie das Kapital der DDR unter
                        den westlichen Kapitalisten aufgeteilt oder
                        beschlagnahmt wurde. Diese verwirrte Situation der
                        BürgerInnen hörte schlagartig auf, als sie mit der
                        Arbeitslosigkeit konfrontiert wurden. Die
                        DDR-BürgerInnen hatten früher nicht gelernt, sich
                        Gedanken über ihre Zukunft zu machen, und nun hatte die
                        neue Regierung ihnen Bananen in die Hand gegeben, damit
                        sie schweigen oder abgelenkt sind und so standen sie
                        auch da. Die Jugend der ehemaligen DDR fing an, alles in
                        Frage zu stellen: die Vergangenheit, die Lügen über die
                        heile und blendende Zukunft... Folglich gerieten sie in
                        eine konfliktreiche und depressive Phase. Zugleich warf
                        die Regierung der Bevölkerung einen Spielball zu, weil
                        sie ihre Versprechungen vom schnellen Wohlstand nicht
                        einhalten konnte und gab den rechten und rassistischen
                        Parteien für ihre Aktivitäten, in den neuen wie alten
                        Bundesländern, grünes Licht, um so von den eigentlichen
                        Problemen abzulenken.
                    </p>
                    <p>
                        Die ganzen rassistischen Übergriffe seit der
                        Wiedervereinigung geschahen in diesem Rahmen, unter der
                        Aufsicht des Bundes Nachrichten Dienstes (BND) . Sie
                        bestimmen mit ihren faschistischen Marionetten den
                        täglichen politischen Zustand in der Bundesrepublik
                        Deutschland.
                    </p>
                    <p>
                        Auf der anderen Seite, wenn der "Ossi" mit seinem Trabbi
                        in den Westen fuhr und dort dunkel-haarige Menschen, mit
                        dunkler Hautfarbe und dunklen Augen, sah, die Mercedes
                        oder Ford fuhren, dann kam bei ihm der Neid hoch. Die
                        Theorien der Faschisten, wer Schuld an der schlechten
                        Wirtschaftslage habe und wie sie zu bewältigen sei,
                        schien sich für die BürgerInnen der neuen Bundesländer
                        allmählich zu bestätigen und es machte sich bei ihnen
                        eine AusländerInnen-feindliche Haltung bemerkbar, obwohl
                        die meisten von ihnen kaum in Berührung mit
                        ImmigrantInnen oder Flüchtlingen kamen. Aber die Lösung
                        wurde ihnen ja auf einem Silbertablett serviert - warum
                        sollte man sich da die Mühe machen, um nach den
                        tatsächlichen Ursachen der Wirtschaftskrise zu forschen.
                        Schließlich bestätigten die Berichte in den Medien und
                        die Beteuerungen der Politiker die allen bekannte
                        Theorie. So gab es eine verstärkte Zuwanderung von
                        BürgerInnen aus den neuen Bundesländern in faschistische
                        Parteien und die Sympathie für sie wuchs. Es herrschte
                        die Vorstellung, daß, wenn die "Ausländer" in ihre
                        Herkunftsländer zurückkehren würden, deren Wohnungen und
                        Arbeitsplätze für Deutsche frei werden würden. So
                        versuchte man, den Glauben der Jugend in den neuen
                        Bundesländern nach einer ideellen Zukunft
                        aufrechtzuerhalten. Während des Kalten Krieges
                        entwickelte die NATO eine Starategie, um die linken und
                        kommunistischen Bewegungen in der Welt im Keim zu
                        ersticken. Dazu versuchte man u.a. in den jeweiligen
                        Ländern, die Kräfte zu unterstützen, deren Aktivitäten
                        sich ebenfals gegen diese linken Srömungen richtete. Bei
                        den nationalen und faschistischen Gruppierungen fanden
                        sie einen Nährboden. Innerhalb der Polizei, Armee und
                        der Geheimdienste wurden ausgewählte Leute ausgebildet,
                        die geschult sind in Folter und Verhör Methoden, die mit
                        den notwendigen Informationen ausgestattet werden, um
                        gezielt die linke Bewegung zu spalten und aufzulösen und
                        gezielte Attentate auf linke Kader und Intellektuelle
                        auszuüben. Besonders in den 60' er und 70' er Jahren war
                        der Waffenverkauf an linken Gruppierungen eine beliebte
                        Methode, um sie so untereinander zu spalten und bei
                        ihnen einen Waffenfetischismus hervorzurufen.
                    </p>
                    <p>
                        Jedes NATO Mitgliedsland hat solch eine eigene "geheime"
                        Struktur, die z.B in der Türkei die "Kontra Guerilla" ,
                        in Italien die "Gladio" genannt wird. In der
                        Bundesrepublik ist uns ihr Name noch nicht bekannt. Die
                        Absicht dieser Struktur in der BRD ist es, längerfristig
                        bestimmte soziale und demokratische Rechte einzudämmen,
                        um eine möglichst schwache Opposition zu haben. Damit
                        würden Bedinungen geschaffen werden, um die Interessen
                        der regierenden und wirtschaftichen Elite, ohne
                        Rücksicht auf die Interessen der Basis, durchzusetzen.
                        Die Taktik zur Erschaffung dieser Bedinung ist, bei der
                        Basis eine unzufriedene Stimmung zu erzeugen, so daß bei
                        ihnen der Wunsch nach einer starken Hand hervorrufen
                        wird.
                    </p>
                    <p>
                        Der erste Schritt zur Eindämmung der zivilen Rechte war
                        die Änderung des "Ausländergesetzes", das relativ leicht
                        durchzusetzen war, weil die sogenannte demokratische
                        Opposition schon seit 30 Jahren keine konsequente
                        Haltung gegenüber dem "Ausländergesetz" gezeigt hatte.
                        Dieser Änderung folgte die Änderung des "Asylrechts",
                        die begleitet wurde von gewalttätigen Angriffen der
                        Rechtsextremen auf Flüchtlinge und Flüchtlingsheime.{" "}
                    </p>
                    <p>
                        Die BürgerInnen aus Hoyerswerder protestierten gegen
                        Flüchtlinge und jubelten bei Angriffen auf deren
                        Unterkünfte. Die Menschen in Dresden gingen gegen
                        ImmigrantInnen und Flüchtlinge auf die Straße und dem
                        folgten die Angriffe gegen Flüchtlinge mit
                        Molotow-Koktails in Rostock. Bei den Angriffen in
                        Rostock war der Staat mitbeteiligt und die Angriffe
                        hielten eine Woche an. Die Staatskräfte wurden bewußt
                        für nicht handlungsfähig und schwach gegenüber der
                        herrschenden Situation in Rostock erklärt. Jedoch, als
                        die AntifaschistInnen eine Gegen-Aktion in Rostock
                        gestartet haben, waren die Staatskräfte mit einem
                        riesiegen Polizeiaufgebot, Polizeipanzern, Wasserwerfern
                        und Hubschraubern präsent und stark genug, um die Aktion
                        zu sprengen.
                    </p>
                    <p>
                        Der Staat hielt auch hier, wie immer, das rechte Auge
                        für die Rechten zu und öffnete das linke Auge gegen die
                        Linken weit auf. Das beste Beispiel dafür ist die PDS.
                        Der Staat kämpft mit allen Mitteln gegen die PDS, weil
                        sie keine "radikale" linke Opposition wollten.{" "}
                    </p>
                    <p>
                        Als die Faschisten in Rostock einen Anschlag auf 200
                        Flüchtlinge verübt haben, erhielten sie klatschenden
                        Beifall von der dabeistehenden Bevölkerung. Dieses
                        nutzte der Staat als Anlaß, um die Basis der
                        SPD-Wählernnen zur einer Änderung des Asylrechts zu
                        bewegen. Wenige Wochen danach wurde im Bundestag die
                        Änderung des Asylrechts verabschiedet und zeitgleich
                        hörten die Angriffe auf Flüchtlinge und ihren
                        Unterkünften nahezu auf.{" "}
                    </p>
                    <p>
                        Mit der Diskussion über die "Innere Sicherheit" des
                        Landes fängt der zweite Teil des Spiels an. Es finden
                        Angriffe auf ImmigrantInnen und ihren Wohnungen statt -
                        vorerst sind BürgerInnen aus der Türkei davon betroffen.
                        In Mölln und Solingen werden schlafende Kinder und
                        Frauen vom Brandansätzen überrascht und sterben. Dadurch
                        werden mit einer Klappe drei Fliegen geschlagen:
                        Erstens, mit den Diskussionen über die "nnere
                        Sicherheit", will man die Änderung der Gesetze
                        beschleunigen und zugleich erhält die Polizei größeren
                        Bewegungsraum, um nach angeblichen "Staatsfeinden"
                        vorzugehen. Zweitens sollen diese Anschläge Ängste bei
                        den ImmigrantInnen auslösen, die bei ihnen
                        Rückkehr-Gedanken hervorrufen. Drittens soll durch
                        Diskussionen wie "das Boot ist voll" die Öffentlichkeit
                        abgelenkt werden, um das Vermögen der ehemaligen DDR
                        ungehindert beschlagnahmen zu können.{" "}
                    </p>
                    <p>
                        Die Unternehmer übten Druck auf die Opposition aus und
                        argumentierten mit der gestiegenen Konkurenz auf dem
                        internationalen Markt. Sie waren nur zu einem Minimum an
                        Lohnerhöhungen bereit im Verhältnis zur gestiegen
                        Inflation. Aber tatsächlich ging es ihnen um die
                        Aufstockung ihres Kapitals, um einen möglichst großen
                        Teil des Weltmarktwirtschaftskuchen abzukriegen. Die
                        Unternehmer, bessesen vom leichten Erwerb des ehemaligen
                        DDR-Vermögens, drohten den <i>"demokratischen"</i>{" "}
                        Kräften, ihre Unternehmen ins Ausland zu verlegen, um
                        ihren Gewinn zu maximieren, weil dort die Arbeitskraft
                        billiger seien. Diese Kräfte nahmen wieder einmal die
                        Position der passiven ZuschauerInnen ein und waren
                        intensiv damit beschäftigt, ihre Basis nicht zu
                        verlieren.
                    </p>
                    <p>
                        Die Politiker, wiedereinmal unfähig, Probleme zu lösen,
                        versuchten, ihre Fehler zu verbergen. Sie mußten also
                        Mechanismen in Bewegung bringen, die unauffällig die
                        bestehenden sozialen Rechte vermindern und demokratische
                        Rechte ändern können. Diese Mechanismen sorgten dafür,
                        daß der Bevölkerung wieder ein Spielball zugeworfen
                        wurde, damit sie abgelenkt wird. Die Informationen und
                        Berichte aus den Medien wurden ohne ein kritisches
                        Bewußtsein aufgenommen. Das selbstständige Denken wurde
                        schon verlernt, denn wozu auch selbst denken, wenn es
                        Journalisten und Politiker gibt, die das Denken für sie
                        übernehmen. In Europa macht sich ein
                        Wohlstandchauvinismus breit. Die Menschen würden so
                        ziemlich auf viele Freiheiten verzichten und nicht
                        zuletzt auf Menschenrechte, um nur ihren Wohlstand unter
                        Garantie zu nehmen. Hinter diesem Hintergrund ist die
                        relativ leichte Durchsetzung der Änderung des Ausländer-
                        und Asylgesetzes zu verstehen. Dieses ist also der erste
                        gelungene Schritt zur Eindämmung der demokratischen
                        Rechte.{" "}
                    </p>

                    <p>Ercan Yaşaroğlu</p>
                </div>
            </Fade>
        </div>
    );
}

export default Artikel0;
