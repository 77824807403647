import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

function Artikel(props) {
    const [heading, setHeading] = useState();

    useEffect(() => {
        if (!props.lang) {
            setHeading("artikel");
        } else {
            setHeading("yazılar");
        }
    }, [props]);
    return (
        <div className="artikel-container">
            <Fade top duration={1000}>
                <h2>{heading}</h2>
                {!props.lang ? (
                    <div className="artikel">
                        <Link to="/artikel/0">
                            <h3>
                                Die Situation der ImmigrantInnen in Deutschland
                            </h3>
                        </Link>
                        <a
                            href="http://medya.berlin/kreuzberg-72-kulturlu-berlin-koyu"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <h3>
                                Kreuzberg: 72 Kültürlü Berlin Köyü (Interview
                                von Ayşegül Aşkım Tezcan)
                            </h3>
                        </a>
                        <Link to="/artikel/1">
                            <h3>Avrupa'daki Modern Kölelerin Durumu</h3>
                        </Link>
                        <Link to="/artikel/2">
                            <h3> Kültür Emperyalizmi</h3>
                        </Link>
                    </div>
                ) : (
                    <div className="artikel">
                        <a href="http://medya.berlin/kreuzberg-72-kulturlu-berlin-koyu">
                            <h3>
                                Kreuzberg: 72 Kültürlü Berlin Köyü (Röportaj:
                                Ayşegül Aşkım Tezcan)
                            </h3>
                        </a>
                        <Link to="/artikel/1">
                            <h3>Avrupa'daki Modern Kölelerin Durumu</h3>
                        </Link>
                        <Link to="/artikel/2">
                            <h3> Kültür Emperyalizmi</h3>
                        </Link>
                        <Link to="/artikel/0">
                            <h3>
                                Die Situation der ImmigrantInnen in Deutschland
                            </h3>
                        </Link>
                    </div>
                )}
            </Fade>
        </div>
    );
}

export default Artikel;
