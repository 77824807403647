import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";

function Bio(props) {
    useEffect(() => {});
    return (
        <div className="bio">
            <Fade top duration={2000}>
                <h2>bio</h2>
                <div className="bio-text">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Cras efficitur et orci id commodo. Duis diam metus,
                        dictum eu tellus ut, malesuada tempor metus.
                        Pellentesque ut augue vel mauris fermentum scelerisque.
                        Phasellus non turpis lorem. Nulla facilisi. Nam non
                        congue dui, imperdiet volutpat eros. Sed neque erat,
                        tincidunt eget euismod eu, cursus vitae nisl. Curabitur
                        mollis, ante eu gravida rutrum, lectus sapien placerat
                        arcu, ac ullamcorper dolor leo ut arcu. Morbi odio
                        purus, rutrum vel ullamcorper in, maximus id massa.
                        Etiam viverra neque eget urna venenatis, eget posuere
                        sem cursus. Quisque sit amet consequat lectus, sed
                        tempus nulla. Vestibulum vel pellentesque leo. Nulla
                        eget quam non quam suscipit faucibus in a ante.
                        Vestibulum laoreet dapibus nunc eu vestibulum. Phasellus
                        ac facilisis ex. Cras fringilla, justo a consectetur
                        lobortis, nunc massa ornare magna, ac finibus quam purus
                        eget orci. Donec ultricies maximus cursus. Curabitur in
                        diam elit. Morbi hendrerit est et eros fermentum
                        sollicitudin. Nunc lorem purus, consectetur in mauris
                        at, imperdiet tempor ex. In in quam ante. Sed non
                        tincidunt arcu. Praesent sodales elementum sodales.
                        Quisque eget ligula ac lorem aliquet mollis. Proin
                        varius odio nec diam pellentesque fermentum. Aenean eu
                        luctus odio, quis mattis eros. Quisque posuere nunc
                        nisi, consequat egestas ligula molestie quis. Praesent
                        sed justo in odio ullamcorper malesuada. Duis mattis, ex
                        id egestas auctor, justo justo dictum ipsum, congue
                        tristique diam nulla sed libero. Nulla gravida at ipsum
                        vitae sagittis. Suspendisse quis lacinia lectus, quis
                        egestas est. Donec tristique accumsan ante, ut dignissim
                        ex sagittis sit amet. Maecenas pharetra neque at massa
                        consequat, at tincidunt est consectetur. Proin sit amet
                        ex massa. Cras dictum aliquam massa id vulputate. Etiam
                        vitae interdum lorem. Etiam sollicitudin ante eget dui
                        imperdiet, eget vestibulum arcu consectetur. Duis massa
                        enim, elementum ac blandit sit amet, rutrum et mi.
                        Vestibulum auctor lectus posuere dolor convallis
                        ultricies. In iaculis dolor sit amet ligula maximus
                        laoreet. Pellentesque aliquet et quam ultrices
                        ullamcorper. Quisque sit amet massa et tortor rutrum
                        pellentesque at at erat. Etiam blandit, nibh in laoreet
                        malesuada, ipsum arcu viverra massa, quis pellentesque
                        eros eros eleifend quam. Sed laoreet lacinia nisi, quis
                        egestas massa finibus sit amet. Ut in massa et felis
                        eleifend tincidunt in eu massa. Donec lorem mauris,
                        pellentesque sed blandit vitae, vulputate ac erat.
                        Nullam velit erat, finibus non sapien a, vulputate
                        eleifend eros. Mauris tincidunt urna sem, nec finibus
                        leo viverra vitae. Aliquam sagittis erat ut augue
                        rutrum, vitae aliquam elit condimentum. Nulla iaculis
                        non sem ut aliquam. Donec vel ex in quam aliquet
                        imperdiet. Donec imperdiet, erat quis commodo laoreet,
                        lacus metus pretium ex, et aliquam dolor arcu ut felis.
                        Mauris et ante egestas, accumsan mauris ut, fermentum
                        arcu. Aliquam odio nulla, suscipit vel lectus in,
                        pellentesque imperdiet dolor. Sed viverra malesuada
                        magna placerat fermentum. Praesent eu lorem nec augue
                        tincidunt sodales. Vestibulum luctus sagittis metus eget
                        tempus. Curabitur sed fringilla lectus. Fusce pulvinar
                        rhoncus purus, quis euismod leo molestie eu. Donec ex
                        mi, dictum eu suscipit vel, mattis at elit. Morbi
                        efficitur mi sed elit viverra, a scelerisque purus
                        lobortis. Mauris felis felis, tincidunt eu aliquam nec,
                        molestie sed elit. Vestibulum ut molestie arcu. Nunc ac
                        ex quis lectus sodales congue a eget orci. Maecenas
                        porttitor eleifend interdum. Donec interdum est quis
                        elit condimentum luctus. Vestibulum ante erat,
                        consectetur eget ex viverra, convallis ultricies elit.
                        Ut et semper enim. Sed sed neque justo. Nullam accumsan,
                        eros quis feugiat pulvinar, libero arcu molestie urna,
                        ut vestibulum enim tortor id nisi. Nulla vehicula
                        volutpat lorem eget vulputate. Cras a purus dictum,
                        elementum felis eu, luctus erat. Pellentesque orci urna,
                        posuere eu pellentesque id, consectetur ac nisi.
                        Curabitur vitae pellentesque ligula. Duis laoreet, risus
                        a maximus cursus, libero lectus rutrum tortor, a
                        convallis risus nibh sed mi. In viverra lorem vitae
                        lectus malesuada, in pretium nisl mattis. Sed in ligula
                        id nisl aliquet fermentum laoreet interdum lorem.
                        Curabitur elementum, massa eu finibus auctor, erat purus
                        convallis libero, at tempus lorem nisi vitae ante. Nunc
                        malesuada, nisi id efficitur convallis, ante felis
                        viverra orci, vel tristique enim elit in ligula. Donec
                        eu mi non ex ultricies posuere ac nec odio. Orci varius
                        natoque penatibus et magnis dis parturient montes,
                        nascetur ridiculus mus. Ut consectetur leo sed ante
                        vulputate, non ornare risus varius. Orci varius natoque
                        penatibus et magnis dis parturient montes, nascetur
                        ridiculus mus. Fusce non bibendum ante. Morbi eu justo
                        diam. Proin at massa viverra, maximus eros et, egestas
                        nisl. Quisque suscipit augue a porta auctor. Class
                        aptent taciti sociosqu ad litora torquent per conubia
                        nostra, per inceptos himenaeos. Nam et eros vel mi
                        mattis egestas non sagittis quam. Etiam eu vulputate mi.
                        Maecenas interdum, purus et ultricies eleifend, urna
                        neque ornare tortor, placerat tempus eros mi in felis.
                        In vitae euismod risus. In mi purus, viverra eu
                        scelerisque at, interdum eget dui. Praesent interdum
                        urna ac felis eleifend, id eleifend diam fringilla.
                        Vivamus mollis tristique eros, non placerat odio
                        convallis quis. Sed scelerisque bibendum nisl, vitae
                        tincidunt orci malesuada vitae. Morbi eu varius sapien,
                        eget rhoncus nulla. Pellentesque pulvinar nisl rhoncus
                        scelerisque dictum. Integer neque libero, aliquam nec
                        rhoncus et, consectetur id dui. Integer vestibulum ex id
                        ex facilisis, in bibendum nunc maximus. Vestibulum nisl
                        nisl, convallis ac augue eu, bibendum facilisis erat. Ut
                        congue porta auctor. Praesent egestas, urna sed faucibus
                        sodales, eros sapien interdum enim, quis mollis risus
                        dolor quis nunc. Pellentesque eu semper tortor. Duis
                        mauris ante, fringilla in odio sit amet, dignissim
                        finibus tortor. Maecenas risus elit, viverra sit amet
                        venenatis et, tempus scelerisque turpis. Donec augue
                        risus, laoreet vel viverra sed, euismod ut turpis.
                        Interdum et malesuada fames ac ante ipsum primis in
                        faucibus. Curabitur fringilla eget est at suscipit.
                        Nulla ac pellentesque urna. Vestibulum facilisis quam
                        nec mauris ultricies pretium. In eget mollis velit,
                        vitae placerat purus. Sed non commodo lorem, nec varius
                        nibh. Nunc vestibulum nunc sit amet justo feugiat
                        vulputate. Ut pharetra libero non tincidunt dapibus. Ut
                        id tellus vestibulum orci finibus rhoncus. Vestibulum
                        interdum nibh eget metus viverra aliquet sit amet
                        imperdiet quam. Pellentesque ut condimentum dolor, ac
                        dapibus augue.
                    </p>
                </div>
            </Fade>
        </div>
    );
}

export default Bio;
