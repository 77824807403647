import React, { useEffect, useState, useRef } from "react";
import { Link, Route, useLocation } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { videosData } from "../data/videosData";
import Play from "../img/play.png";

function Videos(props) {
  const [heading, setHeading] = useState();
  const [alles, setAlles] = useState();
  const [width, setWidth] = useState();
  const location = useLocation();

  const allesLink = useRef();
  const deLink = useRef();
  const trLink = useRef();
  let videosTr = [];
  let videosDe = [];
  let videosAll = videosData;

  for (let i = 0; i < videosData.length; i++) {
    if (videosData[i].lang === "de") {
      videosDe.push(videosData[i]);
    } else {
      videosTr.push(videosData[i]);
    }
  }

  videosDe.sort(function (a, b) {
    return b.date - a.date;
  });
  videosTr.sort(function (a, b) {
    return b.date - a.date;
  });
  videosAll.sort(function (a, b) {
    return b.date - a.date;
  });

  let videosAllList = videosAll.map((each, index) => {
    if (window.innerWidth > 1025) {
      return (
        <div className="video" key={index}>
          <iframe
            title={index}
            width="480px"
            height="270px"
            src={each.src}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <div className="video-text">
            <p style={{ maxHeight: "270px" }}>
              {each.podcastLink && (
                <>
                  Podcast olarak dinlemek icin{" "}
                  <a
                    href={each.podcastLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="podcast-link"
                  >
                    buraya tıklayabilirsiniz.
                  </a>{" "}
                  {"\n"}
                </>
              )}
              {each.info}
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="video" key={index}>
          <a
            href={`https://www.youtube.com/watch?v=${each.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`https://img.youtube.com/vi/${each.id}/hqdefault.jpg`}
              alt=".."
            ></img>
            <img src={Play} alt=".." className="play"></img>
          </a>
          <div className="video-text">
            <p style={{ maxHeight: "270px" }}>
              {each.podcastLink && (
                <>
                  Podcast olarak dinlemek icin{" "}
                  <a
                    href={each.podcastLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="podcast-link"
                  >
                    buraya tıklayabilirsiniz.
                  </a>{" "}
                  {"\n"}
                </>
              )}
              {each.info}
            </p>
          </div>
        </div>
      );
    }
  });

  let videosDeList = videosDe.map((each, index) => {
    if (window.innerWidth > 1025) {
      return (
        <div className="video" key={index}>
          <iframe
            title={index}
            width="480px"
            height="270px"
            src={each.src}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <div className="video-text">
            <p style={{ maxHeight: "270px" }}>{each.info}</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="video" key={index}>
          <a
            href={`https://www.youtube.com/watch?v=${each.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`https://img.youtube.com/vi/${each.id}/hqdefault.jpg`}
              alt=".."
            ></img>
            <img src={Play} alt=".." className="play"></img>
          </a>
          <div className="video-text">
            <p style={{ maxHeight: "270px" }}>{each.info}</p>
          </div>
        </div>
      );
    }
  });

  let videosTrList = videosTr.map((each, index) => {
    if (window.innerWidth > 1025) {
      return (
        <div className="video" key={index}>
          <iframe
            title={index}
            width="480px"
            height="270px"
            src={each.src}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <div className="video-text">
            <p style={{ maxHeight: "270px" }}>
              {each.podcastLink && (
                <>
                  Podcast olarak dinlemek icin{" "}
                  <a
                    href={each.podcastLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="podcast-link"
                  >
                    buraya tıklayabilirsiniz.
                  </a>{" "}
                  {"\n"}
                </>
              )}
              {each.info}
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="video" key={index}>
          <a
            href={`https://www.youtube.com/watch?v=${each.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`https://img.youtube.com/vi/${each.id}/hqdefault.jpg`}
              alt=".."
            ></img>
            <img src={Play} alt=".." className="play"></img>
          </a>
          <div className="video-text">
            <p style={{ maxHeight: "270px" }}>
              {each.podcastLink && (
                <>
                  Podcast olarak dinlemek icin{" "}
                  <a
                    href={each.podcastLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="podcast-link"
                  >
                    buraya tıklayabilirsiniz.
                  </a>{" "}
                  {"\n"}
                </>
              )}
              {each.info}
            </p>
          </div>
        </div>
      );
    }
  });

  useEffect(() => {
    if (!props.lang) {
      setHeading("videos");
      setAlles("alles");
    } else {
      setHeading("videolar");
      setAlles("hepsi");
    }
    if (location.pathname === "/videos") {
      allesLink.current.style.textDecoration = "underline";
      trLink.current.style.textDecoration = "none";
      deLink.current.style.textDecoration = "none";
    } else if (location.pathname === "/videos/tr") {
      allesLink.current.style.textDecoration = "none";
      trLink.current.style.textDecoration = "underline";
      deLink.current.style.textDecoration = "none";
    } else if (location.pathname === "/videos/de") {
      allesLink.current.style.textDecoration = "none";
      trLink.current.style.textDecoration = "none";
      deLink.current.style.textDecoration = "underline";
    }
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [props, location, width]);
  return (
    <div className="videos">
      <Fade top duration={1000}>
        <h2>{heading}</h2>
        <div className="video-nav">
          <Link to="/videos" ref={allesLink}>
            {alles}
          </Link>
          <Link to="/videos/de" ref={deLink}>
            de
          </Link>
          <Link to="/videos/tr" ref={trLink}>
            tr
          </Link>
        </div>
        <div className="videos-container">
          <Route exact path="/videos">
            {" "}
            {videosAllList}
          </Route>
          <Route exact path="/videos/de">
            {" "}
            {videosDeList}
          </Route>
          <Route exact path="/videos/tr">
            {" "}
            {videosTrList}
          </Route>
        </div>
      </Fade>
    </div>
  );
}

export default Videos;
