import React from "react";
import Fade from "react-reveal/Fade";

function Artikel1() {
    return (
        <div className="artikel">
            <Fade top duration={2000}>
                <h2>Avrupa'daki Modern Kölelerin Durumu</h2>
                <div className="text">
                    <p>
                        Yurt dışında yaşayanların sorunlarının temeline inmeden
                        önce, Avrupa’da yaşayan göçmenlerin yaşam koşullarını
                        kısaca ele alalım.{" "}
                    </p>
                    <p>
                        Türkiye’deki iş bulma kurumları Avrupalılara işçi bulma
                        kurumları haline getirildi ve hayvan pazarından at satın
                        alır gibi insanları dişlerine kadar bakarak, sağlık
                        kontrollerinden geçirerek Avrupalılarla tanıştırdılar.
                        Klasik köle pazarından tek farkı, bu işlemlerin açık
                        alanlarda değil, kapalı kapılar arkasında yapılmış
                        olmasıdır. 60’lı yıllarda dönemin siyasetçileri ve
                        firmaları göçmenlere iş vaat etmişlerdi. Ülke yönetimi,
                        radyo ve gazeteler aracılığıyla zenginlik ve refah
                        algısı yaratmış, insanların işe alım sıralarına gönüllü
                        girmeleri sağlanmıştı. En pis, en zor ve en tehlikeli
                        işlerde çalıştırılacaklarını onlara kimse söylememişti.
                    </p>
                    <p>
                        Ne insanımızın duygusal oluşu göz önüne alınmıştı, ne de
                        Avrupalıların değerleri. İnsanımızın, iki bin yıldır
                        taşıdığı birikimi, tecrübesi, aile içi ve toplumsal
                        eğitimi, kuşaktan kuşağa taşıdığı değerlerinin yarattığı
                        kimliği hiçbir şekilde göz önünde bulundurulmadı. Onlar,
                        kendi değerleri ile yaşayan, sevecen, saygılı, onurlu ve
                        mütevazı bireylerdi. Avrupa’ya giderken bile
                        düşündükleri ailelerinin ve kardeşlerinin hayatlarını
                        kurtarmaktı. Kendilerini aileleri için feda etmeye
                        hazırlardı. Buraya geldiklerinde ilk işleri de, onlardan
                        birkaçını daha Avrupa’ya çıkarmak oldu.{" "}
                    </p>
                    <p>
                        Çoğu kırsal alanlardan gelmişti. Kendi ülkelerindeki
                        büyük şehirlere bile yabancı olan bu insanlar, birden
                        kendilerini teknolojik yönden çok gelişmiş bir ülkede
                        buldular. Dilini bile bilmedikleri bu ülkede, insani
                        değerler, yaşam biçimleri, örf ve adetler tamamen
                        farklıydı. Yeni kültüre ayak uydurup kendilerine
                        yabancılaşmaktan korkuyorlardı. Almanya’daki kalış
                        süreleri uzamasına rağmen, kendilerini burada geçici
                        olarak çalıştıklarına ve er ya da geç vatanlarına geri
                        döneceklerine ikna etmişlerdi. Diğer taraftan, onlara
                        sadece işe ve alışverişe yetecek kadar Almanca öğrenme
                        imkanı verilmişti. Aynı memleketten gelen aileleri
                        ziyaret edip, yurt özlemini onlarla beraber gidermeye
                        çalışıyorlardı. Şehrin merkezinde ikamet etseler bile,
                        onlar için yabancı olan bir kültürün dışında ya da
                        kenarında yaşıyorlardı. Avrupalıların artık
                        yaşamadıkları eski semtlere taşınıyorlar ve orada kendi
                        yaşam biçimlerini, kültürlerini, giyim ve yemek
                        alışkanlıklarını devam ettirebilecekleri bir dayanışma
                        kuruyorlardı. Kendi işlettikleri kafelerde buluşuyorlar,
                        çocuklarını kendi kurdukları derneklerdeki Kuran
                        kurslarına gönderiyorlar ve camilerde ve cemevlerinde
                        birlikte ibadet ediyorlardı.{" "}
                    </p>
                    <p>
                        Yeni toplum tarafından konuk işçi olarak algılandıkları
                        ve bu toplumun bir parçası oldukları hissi onlara
                        verilmediği için, yeni kültüre kapı aralayacak bir
                        esneklikleri kalmamıştı. Bilinçaltlarında yeni kültüre
                        geçip kaybolmaktan korkuyor, bu yüzden de kendi
                        kimliklerine, geleneklerine ve özellikle kendi dinlerine
                        koruyucu zırh gibi sarılıyorlardı.
                    </p>
                    <p>
                        Ayrıca, yaklaşık 60 yıldır Alman toplumunun ayrımcılık
                        ve dışlamaları ile başa çıkmak zorundalar.
                        Reddedildiklerini açıkça hissediyorlar. Bir taraftan,
                        dil bilmedikleri için kendilerini koruyamıyorlar, diğer
                        taraftansa kendilerini korumak dahi istemiyorlar, çünkü
                        Almanya’daki ikametlerini hala geçici görüyorlar. Bu
                        durum ise, Alman iş ve para sistemi hariç bütün kültürel
                        etkilere karşı kendini kapattıkları bir edilgenliğe
                        sebebiyet veriyordu. Bu tutum içgüdüsel bir savunmadır
                        ve içgüdüsel bir özgüvene karşılık gelir.
                    </p>
                    <p>
                        Almanya’ya göç ettiklerinden beri süregelen bu toplum
                        dışı davranış, ötekileştirilme, farklı oluşları üzerine
                        kurulu politik söylemler, eksiklikleri üzerine yapılan
                        haberler ise psikolojik hastalıklara ve doğal olmayan
                        davranışlara sebebiyet veriyor. Yüksek öğrenim için yurt
                        dışına çıkmış olan öğrenciler yapmış oldukları uyum
                        programları ve dil kursları ile Almanya’da iktidarların
                        duymak istediklerini söylemiş, Anadolu insanının
                        ötekileştirilmesini ve eksikliklerini kendi sohbetlerine
                        katarak, ne kadar ‘modern’ olduklarını kanıtlama olanağı
                        bulmuşlardır.
                    </p>
                    <p>
                        Bunların yapmış olduğu Almanca ve Türkçe yayınlar
                        sayesinde göçmen ilgisi oluşturulmuştur. Onkel Tom
                        kompleksi ile ana topluma uyum konusunda cevap olarak
                        Almanların duymak istediklerini söyleyen ve kendi kültür
                        çevresinde de Almanlar hakkında atıp tutan bir kesim
                        vardır. Bunun ortaya çıkardığı davranış bozukluğu,
                        gelecekteki kuşaklara aktarılarak ‘yalaka’ kimliği
                        edinilmiştir.
                    </p>
                    <p>
                        Birinci kuşağın getirmiş olduğu insani değerler, gelecek
                        kuşaklara taşınamamış ve yok olan değerler ikinci ve
                        üçüncü kuşaklara aktarılamamıştır.
                    </p>
                    <p>
                        Türkiye’de bu ‘Almancılara’ karşı olan tutum, Avrupa’dan
                        farklı değildi. Üretilen bu ‘Almancı’ sözü ‘yolunacak
                        kaz, sonradan görme, zenginlik, salak’ algısını
                        değiştirdi. Onlar kardeş, bacı, anne, baba diye
                        yaşarlarken sonraları bunun farkına varacaklardı.
                        Kandırıldıklarını ilk defa, müteahhitlerin yaz
                        tatillerinde pusuya yatarak, Almancı avına çıktıklarında
                        ve gayrimenkul fiyatları tavan yaptığında anladılar. Bu
                        60 yıl içerisinde alınan ama içerisinde bir gün bile
                        oturulmayan gayrimenkullardan, akrabalardan ve
                        kardeşlerden yenen kazıkların hikayelerinden yüzlerce
                        roman ve hikaye çıkar. Aşağılanmalara ve Almanca
                        ötekileştirilmelere rağmen; memleket özlemi ve ülkede
                        bıraktıklarının özlemlerinin getirdiği duygusallık ile,
                        yaptıkları aynı hatalar ve kimlik çöküşünün getirdiği
                        başarısızlık duygusu ile kendilerini kanıtlamaya
                        çalışmaya devam ettirildiler. Bir de buna bankerler
                        furyası ve Fadılzadeler eklenince, tüm birikimleri ve
                        mal varlıkları yağmalanmış oldu. Emekli olanlar, boş
                        şişe toplayarak geçim sağlamak durumunda kaldılar.
                        Mağduriyetleri bugün onları bir dizi ‘keşke’lerden
                        dolayı hayata küskün hale getirmiş ve depresif bir
                        duruma sokmuştur. Devletin onları sağmal inek gibi
                        görüp, askerlik gibi resmi işlemlerine karşılık
                        konsolosluklarda harç istemesi, pasaportları yüzlerce DM
                        (Mark) ya da Euro karşılığında değiştirmesi, ‘aptal’
                        yerine konma duygusunu iyice yerleştirmiştir. Ne yazık
                        ki, devletin ‘üvey evladı’ olma durumu, her yerde ve her
                        şeyde yalnız bırakılmaları, son birkaç yıla kadar devam
                        etti.
                    </p>
                    <h4>Çocuklar ve Gençler</h4>
                    <p>
                        Almanya’da yetişen bir gençte, kimlik bunalımı daha sert
                        ortaya çıkıyor. Bir tarafta tutucu ve geleneksel yaşayan
                        bir aile, diğer tarafta ise tamamen farklı dünya
                        görüşleri ve çok sayıda cazip olanakların sunulduğu bir
                        çevre. Genç, bu çevreye girip imkanlarından faydalanmak
                        ve bu çevrenin bir üyesi olmak istiyor. Ama bu noktada
                        aile ile çatışma başlıyor. Ebeveynler çocuklarının
                        yabancılaştığını hissediyorlar. Çocuklar Avrupai müzik
                        dinlemeye, Alman arkadaşları gibi giyinmeye ve kendi
                        dillerine git gide daha az vakıf olmaya başlıyorlar.
                    </p>
                    <p>
                        Ebeveynler çocuklarındaki bu gelişmeye umutsuzca maruz
                        kalıyorlar, çünkü kendileri, kendi kültürlerinde
                        hapsolmuş ve esnekliklerini kaybetmişlerdir. Bu yüzden
                        sık sık gözdağı vererek ve sıkı kontrol ederek
                        çocuklarının kendilerine yabancılaşmasına engel olmaya
                        çalışıyorlar. Fakat gençleri ne etkileyebiliyor ne de
                        denetleyebiliyorlar. Genç ne kadar çok baskıya maruz
                        kalırsa, anne babasına o kadar çok direnç gösteriyor.
                        Ama diğer taraftan çevrenin ve kurumların iğnelemeleri,
                        aşağılamaları, dışlamaları, ayrımcılıkları ve
                        ırkçılıkları devam ediyor. Baba evinden ayrılmak
                        istiyorlar ama tüm çabalarına rağmen çevre tarafından
                        kabul görmüyorlar. Çevreleri ile bağlantı kurmak için
                        „yan giriş“ denilen yerlerden giriyorlar. Onlara çoğu
                        zaman okul kapıları kapalı olduğundan farklı girişler
                        bulmaya çalışıyorlar. Mesela judo, karate, boks ve
                        futbol gibi spor çeşitlerinde şanslarını deniyorlar. Bu
                        sadece başarı getirmiyor, aynı zamanda düşman çevreye
                        karşı bir savunma da oluyor.
                    </p>
                    <p>
                        Bazıları Breakdance, disko dansı gibi yeteneklerini
                        kanıtlayarak kabul görmeye çalışıyorlar. Kendilerini
                        sadece arkadaş çevrelerinde özgür ve güvende
                        hissettikleri için, dayanışma ve kader birliği içine
                        giriyorlar. Kimliklerini birlikte aile ve çevre arasında
                        arıyorlar. Böylece kendilerine kişiliklerin karışımı
                        olan, tutunabilecekleri bir kimlik oluşturuyorlar.
                    </p>
                    <p>
                        Kısacası ´ikinci ve üçüncü nesil ilk nesle göre daha
                        ağır şartlarda mücadele ediyor. Maddi sorunları yok ama
                        buna karşın daha ağır bir kimlik bunalımı ile karşı
                        karşıya kalıyorlar. Ergenlik aşamasını daha da fırtınalı
                        bir şekilde yaşıyorlar ve nadiren bir çıkış yolu
                        buluyorlar. Ayrıca aileden ayrılmak zorunda kalıyorlar
                        ve aynı zamanda iki toplum tarafından da kabul
                        görmemenin ikilemini yaşıyorlar. Berlin’de 25 yaş altı
                        her 5 gençten biri göçmen. Büyük ölçüde Berlin’de doğup
                        büyüdükleri halde sosyalleşmeleri açısından Alman
                        arkadaşlarından açıkça ayırt ediliyorlar. Bunun sebebi,
                        yukarıda belirtilen sebeplerin yanı sıra, yasal yabancı
                        statüsü ve bu statünün gençlerin çift kültürlülüğü için
                        adil olmadığı gerçeğidir. Avrupa’daki yabancı gençlerin
                        Kreuzberg ve Wedding gibi semtlerde iyi bir okul eğitimi
                        alma şansları çok az ve" yabancılar sınıfında" yeterince
                        Almanca öğrenemiyorlar. Bu yüzden baştan okul kariyerini
                        engelleyen ve ilerideki meslek hayatları için kötü
                        şartlar oluşturan engeller konuyor önlerine. Bu ikilem,
                        işverenler ve öğretmenlerin ırkçı tutumları yüzünden
                        daha da ağırlaşıyor.{" "}
                    </p>
                    <p>
                        90’lı yıllarda yaşananların, istenerek yaratılan
                        programlar ile mafyaya malzeme olacak gençlik çeteleri
                        yaratılıyor. Bu çetecilik modasının altında yatan,
                        yabancıların ötekileştirilip gündemde tutularak politik
                        malzeme yapılıp Almanya’da Alman milliyetçiliğin tekrar
                        gündeme getirebilme çabasıdır. Bu çeteciliğin de sosyal
                        çalışanların katkısıyla ortaya çıkarılması, bilerek
                        sağlanmıştır.{" "}
                    </p>
                    <p>
                        Eski Berlin İç İşleri Senatörü Lummer, tüm Berlin’deki,
                        uyuşturucu tüketicilerini ve satıcılarını Kreuzberg
                        semtine taşıyarak, bu bölgede Emniyet güçlerini işlevsiz
                        hale getirerek, provokasyon alanını oluşturdu. Tekrar İç
                        İşleri Senatörünün yeni konsepti çetecilik sosyal
                        eğitmenler yardımı ile hayata geçirildi. Gençlerin
                        uyuşturucu tacirleri ile tanışmaları için ortam
                        sağlanmış oldu. Gençler o günden bugüne kadar satıcı ve
                        tüketici durumuna getirildi. Son yıllarda, semtteki
                        hastanede yılda 200 üzerinde genç tedavi görmektedir.
                    </p>
                    <p>
                        Kızların sosyalizasyonu erkek çocuklarına nazaran çok
                        daha zor. Bir taraftan Anadolu toplumundaki kadının
                        rolünü ailelerinde öğreniyorlar, diğer taraftan okul ve
                        iş yerlerindeki Alman kadınlarının rolü ile
                        yüzleştiriliyorlar.
                    </p>
                    <p>
                        Ailede „özgürlükleri“ ciddi biçimde kısıtlanıyor. Ev
                        işlerine yardım etmek, kardeşlerine bakmak, anne
                        babaları ile akraba ziyaretine gitmek zorundalar. Tüm
                        boş zamanları aile tarafından belirleniyor. Anne
                        babaların kız çocuklarına karşı tutumları anne babaların
                        küçük sosyal toplulukları tarafından belirleniyor.{" "}
                    </p>
                    <p>
                        Kız çocuklarının hareket imkanları kısıtlıdır çünkü
                        kızları bir kez dahi dışarı çıkarsa başka insanlar ne
                        düşünür, ne olur diye olmayacak şeyler ortaya atarlar.
                        Ebeveynlerin kendi sosyal çevrelerinde, başka bir
                        toplumda yaşamalarına rağmen, dokunulmasını
                        istemedikleri ve neredeyse tabulaşmış değerleri ve
                        standartları vardır.
                    </p>
                    <p>
                        Açıkça tanımlanmış roller ve tabuların yanı sıra evlilik
                        için en öncelikli yasak cinsel ilişki yasağı. "Demokrasi
                        ailede büyür" trajikomedisinde anne babalar yönetici
                        kontrol kuvveti rolünü oynuyorlar. Kız çocuklarına
                        halkın değil, tutsakların rolünü veren bu kuvvetler
                        ayrılığı, kız baba evinden koca evine gidene kadar devam
                        eder. Anne baba sınırsız gurur yasar, çünkü "kutsal
                        görevlerini" yerine getirmişlerdir.
                    </p>
                    <p>
                        Bu olaylar kızın aile dışındaki hayatına nasıl etki
                        ediyor? Kızlar için karşı konulması çok zor olan namus
                        ve şeref gibi tabular kendi değer ve standartlarını
                        oluşturma konusunda engeldir.
                    </p>
                    <p>
                        Burada söz konusu ikinci nesil, yani okul, mesleki
                        eğitim, işyeri gibi alanlarda Alman gençlerle bir araya
                        gelen gençlerdir. Bu bağlamda bütünleşme problemi öne
                        çıkıyor ki, insanın aklına takılan kızların entegrasyon
                        sorusu kendiliğinden ortadan kalkar, çünkü anne baba
                        kızlarını her türlü aile dışı ortamdan uzak tutarlar.
                    </p>
                    <p>
                        Anne babaların, çocuklarını korumak zorunda olma
                        yükümlülüğü, bir taraftan kızlarına güvenmediklerini
                        gösteriyor, diğer taraftan bu güvensizlik bir kanıttır
                        ki, anne babalar tabi oldukları sosyal çevrelerinin bir
                        parçası olan bu yanlış yetiştirmenin farkındalar, kendi
                        hatalarını görmek istemiyorlar ya da, göremiyorlar.
                        Sabit fikirlerinin etkisi altında kalmaya devam
                        ediyorlar ve kızlar da iki ahlaklı değerler altında
                        kimliklerini bulmaya çalışıyorlar.
                    </p>
                    <p>
                        Bu çalışmamızda nihai hedefimiz kadınları ve erkekleri
                        kimlik gelişimlerinde desteklemek ve bu sebeple Türkçe
                        yayın yapan Radyo, TV, Günlük Gazetelerde de kadınlar ve
                        cinsiyet ayrımı hakkında makaleler yazmak.
                    </p>
                    <p>
                        Toplumdaki bazı insanların cinsiyet ayrımcı
                        davranışlarını ne yazık ki azaltamadık. Cinsiyet
                        ayrımcılığı ve kadın dayanışması ile yoğun bir şekilde
                        meşgul olan kadınların bir araya gelmesi, ne yazık ki
                        mümkün olmamıştır.{" "}
                    </p>
                    <p>
                        Göçmenler katlanıcı ve edilgen davranışları
                        değiştirmeliler ve bu çalışmamızla daha emin
                        davranmalılar ki, Almanya’nın "yabancı politikası"
                        başarısızlığa uğrasın. Böylece "Alman Beyefendilere"
                        büyük bir sürpriz hazırlayabilirdik, fakat ne yazık ki
                        bunu başaramadık.
                    </p>
                    <p>
                        Göçmenlerin bu tür farkındalık süreci sağlıklı bir
                        bütünleşme için olmazsa olmazdır, ama burada
                        bütünleşmeyi tek yönlü düşünmek doğru olmaz. Bütünleşme
                        daha çok eşit yasal koşullar altında farklı sosyal grup
                        ve topluluklar arasındaki karşılıklı ve çok yönlü
                        alışveriştir.
                    </p>
                    <p>
                        Resmi Alman bütünleşme politikası gerçek kavramın
                        yarısını anlıyor. Biz siyasetçilere bütünleşmeye olan
                        bakışımızı aktarmak istedik, bütünleşmeden
                        anladıklarının ırkçı ve kabul edilemez olduğunu
                        göstermek istedik
                    </p>
                    <p>
                        Alman toplumunun ben merkezli yaşayış tarzından
                        kurtulup, giderek ağırlaşan hayat şartlarına karşı
                        duyarlılık geliştirmesi gerekir. Siyasetçilerin yaptığı
                        gibi bütün suçu göçmenlerde aramamaları ve onları günah
                        keçisi yapmamalıdırlar, fakat giderek kötüleşen hayat
                        şartlarının sadece ve yalnız yanlış politikadan
                        kaynaklandığını görmelidirler. Göçmenler eşit vatandaş
                        olarak tanınmalı ve eşit haklarla donatılmalı ki, aynı
                        durumda, eşit seviyede ortak hak talepleri ve
                        çıkarlarını temsil edebilsinler. "Bir demokraside her
                        şey açıkça konuşulabilir" sloganıyla Cumhuriyetçiler ve
                        AFD toplumda kabul gördüler.
                    </p>
                    <p>Almanlar bunu kabul etmeli.</p>
                    <p>
                        Fakat yaklaşık 60 yıldır biz göçmenlerin hakları
                        mecliste dikkate alınamıyor. Meclislere seçip
                        gönderdiğimiz milletvekilleri de yalaklık yaparak
                        bireysel kariyerlerini ön planda tutarak kendi seçmenini
                        ötekileştiriyor. Almanya’nın dış politikasının
                        sözcülüğüne soyunuyorlar. Almanya Dış İşleri
                        Bakanlığı’ndan daha fazla dış politika yaparak
                        göçmenlerin gelmiş olduğu ülke yönetimlerinin
                        eleştirilerinde yer alıyor ve göçmenlerin hakları yerine
                        onların daha ince bir şekilde ötekileştirilmeleri
                        görevlerine devam ediyorlar. Son üç yıl da bunu
                        kanıtlamıştır. Bu iktidar partileri tarafından
                        engellendi ve engellenmeye devam ediyor.{" "}
                    </p>
                    <p>
                        Göçmen haklarının sesi olmak ve sosyal süreçte katılım
                        haklarını elde etme gibi nedenlerden ortaya çıkan,
                        derneklerde yaşam alanlarındaki sorunlar yerine, uyum
                        sağlama bahaneleri ve konseptleri ile göçmenin
                        ötekileştirilmesi, şu andaki durumu anlatarak
                        eksikliklerin ve farklılıkların tartışılmasının baş
                        sorumlularıdır. Hayata geçirdikleri politika
                        senatörlerin ve devletin politikalarına uygun şekilde
                        devam etmektedir. Almandan daha alman davranarak kendi
                        insanın ötekileştirilmesinde Alman sağcıları kadar
                        rolleri verdir.
                    </p>
                    <h4>Yeni Gelinler ve Damatlar</h4>
                    <p>
                        Burada yetişen ikinci ve üçüncü kuşak evliliklerini daha
                        çok Türkiye’den yapmaktadır. Nedeni, bu toplumda her
                        şeyi yaşayan haylaz erkek tiplemelerinin yapmış olduğu
                        evliklerden dolayı Almanya’ya gelen köle gelinlerimiz,
                        seks kölesi ve hizmetçi olarak kullanılarak
                        tüketilmektedirler. Bu gelinler 5-10 sene içerisinde
                        ayrılmayı başarıp 2-3 çocuk ile yaşamlarını kurmaya
                        çalışırken, memleket ve orada bıraktıkları ailenin
                        (anne, baba, kardeşler) özlemi ile yanıp
                        tutuşmaktadırlar.
                    </p>
                    <p>
                        Bunların çocukları 10-11 yaşına gelmiş olmalarına rağmen
                        Türkiye’ye ekonomik şartlardan dolayı seyahat edememiş,
                        memleketi ve ailesinin yarısını tanımadan
                        büyümektedirler. Bu memleket özlemi ile kendilerinin
                        içinde bulundukları durumu kabullenemeyişlerinden dolayı
                        ortaya çıkan üzüntü onları psikolojik olarak aşağıya
                        çekmektedir.{" "}
                    </p>
                    <p>
                        Damat olarak gelenlerin durumu ise şöyledir. Türkiye’de
                        meslek sahibi olan ve geleceğe umut ile bakan enerji
                        dolu gençler, Almanya’ya geldiklerinde beklentileri ile
                        gerçekliğin ne kadar farklı olduğunu yaşayarak görürler.
                        Ergen delikanlılar gibi dil eksikliği yaşarlar,
                        çalışamamaktan dolayı ekonomik olarak eşinin eline bakar
                        ve ondan cep harçlığı almak zorunda kalırlar, bu
                        kabullenmesi zor durumlar ise onurlarını zedeler. Eşinin
                        de cicim ayları veya yılları geçtiğindeki davranış
                        bozuklukları (değişiklikleri) kişisel yenilgi olarak
                        algılanır. Eşinden kurtulamaz ve yol, ortam bilmemenin
                        çaresizliğiyle kahve köşelerinde en değerli olan şeyi,
                        zamanını harcar.Erkek geriye dönemez, dönmesini kişisel
                        yenilgi olarak algılar ve ülkede söylenecek laflar
                        gururuna yediremeyeceğinden bu ülkede tüketilen
                        delikanlılar olarak kalırlar. Onların çocukları da
                        burada erittiğimiz, sahip çıkamadığımız, kaybolan
                        çocuklar olarak sürünürler.
                    </p>
                    <h4>Alman Toplumunun Göç İle İlişkisi</h4>
                    <p>
                        60'lı yıllarda göçmenlere Almanya’ya çalışmaya
                        geldikleri, Alman halkına ise göçmenlerin "konuk işçi"
                        oldukları ve Almanya’nın ekonomik durumu rayına oturunca
                        geldikleri ülkelere geri dönecekleri fikri empoze
                        edildi. Bu anlayıştan 70’li yılların ortalarına kadar
                        göçmenler için resmi olarak kullanılan ve hala bazı dar
                        çevrelerde kullanılan konuk işçi kavramını
                        anlayabiliyoruz. Alman halkı medyanın ve siyasetçilerin
                        etkisi altında kaldı. Bu hikayelere inandılar, çünkü
                        belki de bu yalanlar işlerine geliyordu.
                    </p>
                    <p>
                        Yurt dışından işçi getirmenin asıl sebebi, işveren ile
                        işçi arasındaki güç dengesinde yatıyor. 1960 yılında
                        yaklaşık 1 milyon işsiz olmasına rağmen, bu işsizlerden
                        yararlanmak yerine, yurt dışından ucuz iş gücü getirmeyi
                        yeğlediler. Bununla, işçi pozisyonunun işveren
                        karşısında güçlenmesini engellemek ve aynı zamanda
                        Sosyal Demokrat Parti ve Alman Sendika Birliği’nin
                        demokratik güçlerini kontrol altında tutmaktı.{" "}
                    </p>
                    <p>
                        Konuk işçi mantığına göre, insanın aklına göçmenlerin
                        lazım olmadıklarında iade edilebilecekleri ya da yenisi
                        ile değiştirilebilecekleri üretim makineleri geliyordu.
                        Bu göçmenler makine değil, insandır. Ailelerini
                        memleketlerinde bıraktılar ve yeni çevrelerine
                        yerleşince onları yanlarına aldılar. Bu gelişme, medyada
                        ve siyasetçilerin anlatımlarında yer almıyordu ve Alman
                        halkına da sürpriz oldu. 1972'deki petrol krizinden
                        sonra göçmenlerin geri dönüşü ciddi ciddi düşünülmeye
                        başlandı. Medya, özellikle Springer yayınevinin BİLD
                        yada Berliner Zeitung gibi sansasyon basını/ magazin
                        basını göçmenlere karşı Alman toplumu ile göçmenler
                        arasındaki çatışmaları körükleyen önyargılar
                        oluşturuyorlardı. Bu siyasetçilerin işine geliyordu,
                        böylece medya başarılı olmuştu.
                    </p>
                    <p>
                        Almanların çoğu geçmişlerindeki tecrübelerinden dolayı
                        1980 yılına kadar yabancı düşmanlıklarını
                        gösteremediler. 1980'den sonra Berlin Duvarına
                        "Yabancılar Dışarı" veya "Türkler Dışarı" yazmaya
                        başladılar. Hükümet 1982 ve 1984 arasında geri
                        dönenlere/ geri dönmeye gönüllü olanlara ikramiye
                        planları geliştirmeye başladı. Göçmenler bu ikramiye ile
                        memleketlerinde iş imkanı oluşturabilirdi. Hükümet
                        böylece gelecekteki zor durumlardan kurtulacaktı. Bu
                        plan bazı az sayıda göçmen tarafından kabul gördü, ama
                        geniş göçmen kitlelerinde karşılık bulmayınca,
                        "Yabancılar Dışarı" sloganı daha güçlü bir şekilde
                        haykırılmaya başlandı. Ama Alman geçmişindeki tabular
                        henüz yıkılmamıştı. Almanya’nın insan haklarını koruyan
                        "demokratik" bir ülke olması görüntüsü altında dönemin
                        Alman Demokratik Cumhuriyetinden siyasi tutukluları
                        satın alıp, bunların bir kısmını gerek görüldüğünde
                        sağcı ve ırkçı kuruluşlarda çalıştırdılar. 1989'daki
                        seçimlere cumhuriyetçilerin ve bugünün AFD’sinin ortaya
                        çıkmasına izin verilmesi ile katılımına katkı sağlanacak
                        bu tabuların yıkılması imkanı doğdu. Cumhuriyetçiler ve
                        AFD seçim propagandalarında açıkça ırkçılığa vurgu
                        yaptılar. Bu ırkçılığın tek hedefi Anadolu insanının
                        ötekileştirilmesi için farklılıkları ve eksiklikleri
                        konu edilir hale getirildi. Bütün "demokratik" güçler
                        suskun ve pasif bir duruş sergilediler. Kimse
                        Almanya’nın nereye gittiği konusunda endişelenmedi. "Bir
                        demokraside herkes her şeyi açıkça konuşabilir"
                        sloganıyla Cumhuriyetçiler toplumda kabul gördü.
                    </p>
                    <p>
                        Hükümetin yetersizlikleri ve hataları için açık suçlu,
                        bir günah keçisi bulunmuştu: göçmenler. Eskiden tabu
                        olan birçok davranış biçimini Cumhuriyetçiler normal
                        hale getirdiler. Almanlar artık Alman olmanın ve
                        Almanya’nın geleceği hakkındaki endişelerini açıkça
                        dillendirebilmeye başladılar. Artık diğer partilerde
                        Cumhuriyetçiler ile aynı fikirleri savunmaya başladılar-
                        tabular kaybolmuştu. Bu durum duvarın yıkılmasından
                        sonraki süreçte ve bugün devam etmektedir. Bundan sonra
                        Almanya’daki durum yavaşça Nazi Almanyası’ndaki düşünce
                        tarzının geliştirileceği ortamı yaratmış oldu.{" "}
                    </p>
                    <p>
                        Duvarın 1989'da yıkılmasıyla yeni bir evrensel güç
                        dengesi ortaya çıktı ve böylece Almanya’da yeni bir
                        siyasi bakış açısı açıldı. Bizler hızla vuku bulan
                        gelişmelerle meşgul olurken, diğer taraf Demokratik
                        Almanya Cumhuriyeti vatandaşlarını muz ile beslemekle
                        meşguldür. Özgürlük şarkıları söyleyip Federal
                        Almanya’daki "zengin kardeşleri" ile birlik olmayı
                        kutluyorlardı. Bu ortamı mülteciler için geliştirilen
                        „Hoşgeldin Kültürü“ ile tekrar 1990 yarın ruhu
                        yaratılmış oldu.{" "}
                    </p>
                    <p>
                        Göçmenler kısmen onlarla kutlamaya katılıyorlar ve
                        kısmen de olayları dışarıdan sessizce gözlemliyorlar.
                        Siyasetçiler vatandaşlarına parlak bir gelecek vaat
                        ediyorlar.1990’lı yıllarda Federal Almanya’nın parlak ve
                        zengin görünümünden gözleri kamaşmış Demokratik Alman
                        vatandaşları onlara söylenen her şeye inandılarsa da, bu
                        günde söylevlerdeki konu göçmenler de olsa, cümle
                        aralarında geleceğin göçmenleri ile birlikte yaşama
                        ortamının daha iyi olacağı vaatlerinde bulunurken,
                        duvarları geri dönüş teşviği afişleri ile süslüyorlar.
                    </p>
                    <p>
                        Böylece Demokratik Alman Cumhuriyetinin sermayesine el
                        konup, batılı işverenler arasında paylaştırıldığını
                        sessizce kabullendikleri gibi, bugün de kendi sosyal
                        sistemlerinin ve refahın düşmesini kabulleniyorlar.{" "}
                    </p>
                    <p>
                        Alman vatandaşları eskiden gelecek ile ilgili endişe
                        etmeyi öğrenmemişlerdi, ve şimdi iktidar, susmaları ve
                        dikkatlerinin dağılması için ellerine muz vermişti,
                        onlar da öylece kalakaldılar. Eski Demokratik Alman
                        Cumhuriyeti gençleri her şeyi sorgulamaya başladılar:
                        güvenli ve göz kamaştırıcı gelecek ile ilgili yalanlar.
                        Sonuç olarak çatışmalarla dolu ve bunalımlı bir süreç
                        başladı onlar için. Aynı zamanda iktidar halka bir oyun
                        topu attı, çünkü hızlı bir refah sözünü tutamadı ve sağ
                        ve ırkçı partilerin faaliyetlerine hem yeni hem eski
                        eyaletlerde yeşil ışık yaktı ki esas sorunlar unutulsun.
                        Bu süreç, Kraliyet vatandaşlarının yeraltına çekilen
                        silahlı 450 eylemci faşistin güvenlik güçleri tarafından
                        „yakalanmayışları“ bizlerdeki tedirginliği daha da
                        artırdı.
                    </p>
                    <p>
                        Yeniden birleşmeden sonra bütün ırkçı saldırılar bu
                        çerçevede ve Alman İstihbarat Örgütünün gözetimi altında
                        gerçekleşti. Faşist kuklaları ile Almanya’daki günlük
                        siyasi durumu belirliyorlardı. Son bir yıl içinde 750
                        Camiye ve işyerine saldırılar oldu.Diğer taraftan bir
                        Doğu Alman Trabant marka otomobili ile batıya
                        gittiğinde, Mercedes ya da Ford kullanan koyu renk
                        saçlı, esmer tenli ve koyu renk gözlü insanları görünce
                        kıskanmaya başladı. Bu gün de faşistlerin kötü ekonomik
                        durum ve bunun düzeltilmesi ile ilgili teorileri yeni
                        eyaletlerin insanları tarafından yavaş yavaş onaylanıyor
                        ve yabancı düşmanı tutumları hissediliyor; birçoğunun
                        göçmenlerle ve mültecilerle hemen hemen hiç iletişime
                        geçmemiş olmasına rağmen. Ama çözüm onlara gümüş tepside
                        sunuldu; insanların refah ortamından içine düştükleri,
                        ekonomik krizin gerçek sebeplerini araştırmak için çaba
                        sarfetmeye ne gerek vardı ki. Sonuçta medyanın tüm
                        haberleri ve siyasetçilerin yeminleri herkesçe bilinen
                        teoriyi onaylıyordu. Buna göre yeni eyaletlerin
                        vatandaşları faşist partilere gittikçe yakınlaşıyorlar
                        ve onlara sempatileri artıyordu. "Yabancılar geldikleri
                        ülkelere geri dönerlerse onların evleri ve işleri
                        Almanlara kalacak" fikri hakim durumdaydı. Böylece yeni
                        eyaletlerin gençlerinin ideal gelecek inançları canlı
                        tutulmaya çalışılıyordu.
                    </p>
                    <p>
                        Soğuk savaş döneminde dünyadaki sol ve demokratik güçler
                        NATO ülkelerinde derin ilişkilere sokulup asıl
                        amaçlarından saptırıldı. 1968 hareketindeki değerler
                        yerine (mütevazi yaşam alanları içinde sorumluluk alan
                        gençlik anti emperyalist duruşunu da kaybederek sadece
                        teorik ezberciliğe ve şiddet yanlısı silah ile
                        tanıştırılmış oldu. Bir kaşık suda fırtınalar yaratan
                        strateji uygulanıyordu. Böylece söz konusu ülkelerde,
                        sol akımlara karşı faaliyet yürüten güçleri desteklemeye
                        çalışıyorlardı. Kontrgerillaya bu gruplarda besleyici
                        zemin buldular. Polis, ordu ve istihbarat örgütlerinde
                        seçilmiş kişiler yetiştiriliyordu. Bunlar işkence ve
                        sorgulama metodu öğreniyorlardı. Sol akımı bölüp yok
                        etmek, solcu kadroya ve bilim adamlarına hedef gözetip
                        suikast düzenlemek için gerekli bilgilerle
                        donatılıyorlardı. Özellikle 60'lı ve 70'li yıllarda
                        solcu gruplara silah satmak sevilen bir yöntemdi.
                        Böylece onları kendi aralarında bölüp, bir silah
                        fetişizmi oluşturuyorlardı.{" "}
                    </p>
                    <p>
                        Her NATO üyesi ülkede böyle bir gizli yapı var. Örneğin
                        Türkiye’de "kontrgerilla", İtalya’da "gladyo" gibi.
                        Almanya’da ise adını bilmiyoruz. Almanya’daki bu yapının
                        amacı uzun vadede sosyal hakları önleyip muhalefeti
                        zayıflatmak. Böylece tabanın görüşlerini dikkate almadan
                        iktidarı ve ekonomiyi elinde tutanların ilgilerine
                        yönelik şartlar oluşabilir. Alman solu devletine hizmet
                        vermek, algı operasyonları geliştirmek için kendi içinde
                        zemin olgunlaştırılmasıdır. Irak savaşının öncesi ve
                        alman üçüncü dünya politikasının ve yurt dışı
                        operasyonlarının şekillendirilmesi konusunda diğer
                        partilerden farklılık göstermez duruma getirilmiştir.
                        „Demokrasi“ tartışmalarının ve „Diktatör“ sözcükleri
                        tartışması, bu durumu oluşturmak için ülkenin iç
                        politikasındaki taktik, üçüncü dünyada zeminde
                        huzursuzluk yaratıp, kendilerine yakın bir politikanın
                        gelmesini hayata geçirme arzusu oluşturmaktı. (Türkiye,
                        Ukrayna, Venezuella ve Iran). Bu ülkelere getirilen
                        suçlamalar ile kendilerine işbirlikçi yabancıların
                        ortaya çıkışı ve onlar üzerinden diğerlerinin
                        ötekileştirilmesi hayata geçiriliyor. Yabancıların
                        Demokratik hakların kısıtlanması için ilk adim
                        yabancılar yasasını değiştirmekti. Bunu yapmak nispeten
                        kolay, çünkü demokratik muhalefet 60 yıldır bu
                        yabancılar yasasına karşı tutarlı bir tutum sergilemedi.
                        Bunun ardından değiştirilen iltica yasası ile birlikte
                        mültecilere ve mülteci yurtlarına saldırılar başladı.
                    </p>
                    <p>
                        Köln’de ortaya çıkan provokasyon , mülteciye
                        saldırdığında, etraftaki halk tarafından kabul görmüş ve
                        Almanya’da gelişen, ‘hoş geldin kültürü’ de bitirilmiş
                        oldu. Devlet bunu bir fırsat olarak kullanıp Sosyal
                        Demokrat Parti seçmenlerinin tabanını mülteci yasasını
                        değiştirmeye ikna etmiştir. Birkaç hafta sonra Federal
                        Almanya Parlamentosu’nda mülteci yasası tartışılmaya
                        başlandı ve aynı anda yabancılara ve onların konutlarına
                        yapılan saldırılar sınırsız bir boy aldı.{" "}
                    </p>
                    <p>
                        Ülkenin "iç güvenliği" ile ilgili tartışmayla ise oyunun
                        ikinci bölümü başlıyor. Göçmenlere ve onların
                        konutlarına saldırılar başlıyor, Türkiye’den gelen
                        göçmenler bundan en çok zarar gören kesim. Alman
                        meclislerinde bulunan belli bir milletvekili kimliği de,
                        iki devlet adamının tartışmasını fırsat bilerek
                        Türkiye’den gelenler üzerindeki oyunu tekrar su yüzeyine
                        vurdu. AKP seçmeni, demokrat olmama tartışmaları genel
                        anlamda tüm ötekileştirme aksiyonlarının başlamasına
                        neden oldu. İslam korkusu tartışmaları ve İslam’ın
                        ılımlı hale getirilmesi aksiyonları yalakalar tarafından
                        harekete getirilince böylece bir taşla 3 kuş vurulmuş
                        oluyor. Bunlardan birincisi, yasaların çıkmasını
                        hızlandırmak ve böylece polise sözde yabancılara karşı
                        daha geniş hareket alanı oluşturmak. İkincisi,
                        göçmenleri korkutup geri dönüşü düşündürmek. 60 yıldır
                        gösterilen kapının dışı yüzsüzlüğünün devamlılığı
                        sağlanmış oluyor.{" "}
                    </p>
                    <p>
                        Bizlerin yurt dışında yasayan insanlarımıza daha fazla
                        özgüven vermemiz için ülkenin insani değerleri ile
                        Inancin bir yasam tarzı oldugunu bilince çıkarmalarını
                        ve Avrupa merkezli düsünce tarzının kendilerine
                        yabancılaşmak oldugunu, Anadolu insanin Yasam tarzı,
                        insan sevgisinin ve degerlerinin tasinmasi
                        geliştirilmesi icin harcanan cabalar yetersiz
                        kalmaktadır.
                    </p>
                    <p>Ercan Yaşaroğlu</p>
                </div>
            </Fade>
        </div>
    );
}

export default Artikel1;
