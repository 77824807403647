export const videosData = [
  {
    src: "https://www.youtube.com/embed/1t_ODh3e3_g",
    id: "1t_ODh3e3_g",
    lang: "tr",
    date: 20200926,
    info: `Kültürlerin çatışma ve birleşme noktası Kreuzberg’i, Ercan Yaşaroğlu’nun ağzından dinledik. Gerek siyasi gerek kültürel farklılıkların buluştuğu semt Kottbusser Tor, yaşanan problemler ve bekleyen çözümleri ile tek bir röportaja sığdırılamayacak kadar büyük bir derinliğe sahip.
Ercan Yaşaroğlu kimdir diye başlayalım isterseniz önce. Biraz kendinizden bahsederseniz, Berlin’e gelişiniz nasıl oldu?`,
    podcastLink:
      "https://letscast.fm/sites/ercan-yasaroglu-mister-kreuzberg-4d9c5703/",
  },
  {
    src: "https://www.youtube.com/embed/y7gn2gqBhwU",
    id: "y7gn2gqBhwU",
    lang: "de",
    date: 20160118,
    info: `Gibt es in Berlin rechtsfreie Räume? Immer mehr Drogenkriminalität und Taschendiebstähle gibt es in der Hauptstadt, die Polizei scheint überfordert. Der Brennpunkt Kottbusser Tor steht im Fokus.
Morgenmagazin vom 18. Januar 2016`,
  },
  {
    src: "https://www.youtube.com/embed/1quqcAdV9cY",
    id: "1quqcAdV9cY",
    lang: "de",
    date: 20170510,
    info: `Seit 30 Jahren gehört das Kottbusser Tor zu den Berliner Problemplätzen.`,
  },
  {
    src: "https://www.youtube.com/embed/o7u6DGTWrRA",
    id: "o7u6DGTWrRA",
    lang: "tr",
    date: 20180609,
    info: `Almanyada 849 failimeçhul cinayet dosyası tekrar açıldı. Failimeçhullerin aşırı sağcı guruplarca işlendiği düşünülüyor.`,
  },
  {
    src: "https://www.youtube.com/embed/86cssXPfyqI",
    id: "86cssXPfyqI",
    lang: "tr",
    date: 20170827,
    info: `MEDYABERLİN- Sosyal danışman Ercan Yaşaroğlu yıllardır Kottbuser Tor yani KOTİ olarak bilinen semtte yaşanan sorunların arkasında yaşananların özellikle Türk toplumunun ötekeleştirmesine sebep olduğunu söyledi.`,
  },
  {
    src: "https://www.youtube.com/embed/N2H7GNVVEeY",
    id: "N2H7GNVVEeY",
    lang: "tr",
    date: 20170827,
    info: `MEDYABERLİN-  Sosyal Danışman Ercan Yaşaroğlu MedyaBerline yaptığı açıklamada  24 Eylül'deki Almanya genel seçimlerinde  Türkiye  kökenli  göçmenlerin eğer oy hakları varsa bunu kullanmalarını şayet kullanmadıkları taktirde bunun ırkcıların işine yarayacağını söyledi.`,
  },
  {
    src: "https://www.youtube.com/embed/pclInei6fkI",
    id: "pclInei6fkI",
    lang: "de",
    date: 20130507,
    info: `Sozialarbeiter Ercan Yasaroglu betreibt seit 2009 im Neuen Kreuzberger Zentrum (NKZ) das Café Kotti - eine Begegnungs- und Beratungsstätte zugleich.`,
  },
  {
    src: "https://www.youtube.com/embed/wYbwDGSOfCU",
    id: "wYbwDGSOfCU",
    lang: "de",
    date: 20180321,
    info: `Kurdisch-türkischer Konflikt in Deutschland: "Wenn die Gewalt eskaliert, profitieren nur die Rassisten"
    Mittwoch, 21.03.2018   16:02 Uhr Das "Café Kotti" in Berlin ist eine Kreuzberger Institution. Café-Betreiber Ercan Yasaroglu, 59, ist nicht nur Gastwirt, sondern auch Sozialarbeiter. Er kennt die Konf...`,
  },
  {
    src: "https://www.youtube.com/embed/JxJFyp0dtIk",
    id: "JxJFyp0dtIk",
    lang: "de",
    date: 20130208,
    info: `Interview - Ercan Yaşaroğlu
            CAFE-INHABER`,
  },
  {
    src: "https://www.youtube.com/embed/vV2M-Q4iik4",
    id: "vV2M-Q4iik4",
    lang: "de",
    date: 20160302,
    info: `Kottbusser Tor Berlin Kreuzberg: Steigende Kriminalität. Ercan Yasaroglu beklagt Kriminelle in Berlin aus Nordafrika, Algerien, Marokko, Tunesien und Libyen.`,
  },
  {
    src: "https://www.youtube.com/embed/QhJeUKGrgO4",
    id: "QhJeUKGrgO4",
    lang: "de",
    date: 20170531,
    info: `Berlin Kottbusser Tor: Gewalt, Drogen und Kriminalität stern TV (31.05.2017)`,
  },
  {
    src: "https://www.youtube.com/embed/-u7AzrXn4Pk",
    id: "-u7AzrXn4Pk",
    lang: "de",
    date: 20170315,
    info: `Vor allem, wenn es dunkel wird, fängt das Kottbusser Tor an zu leben. Junge, arabische Männer stehen zusammen oder laufen hin und her. Sie beobachten, wer ein gutes Opfer sein könnte.`,
  },
  {
    src: "https://www.youtube.com/embed/r2PYJ9XcwZY",
    id: "r2PYJ9XcwZY",
    lang: "de",
    date: 20090627,
    info: `Eine kurze Dokumentation über Drogenabhängige am Kottbusser Tor in Berlin.`,
  },
  {
    src: "https://www.youtube.com/embed/kk3hYlG1oJI",
    id: "kk3hYlG1oJI",
    lang: "de",
    date: 20200211,
    info: `Für die Anwohner des Kottbusser Tor oder der Schönleinstraße ist es längst zu einem echten Ärgernis geworden: die Drogenszene ist im Kiez außer Kontrolle geraten. Aus der ganzen Republik pilgern die Süchtigen und Dealer zum "Kotti", dem Umschlagplatz für Drogen aller Art. Auch die U-Bahnlinien sind betroffen.`,
  },
  {
    src: "https://www.youtube.com/embed/KNmUXSbNIhc",
    id: "KNmUXSbNIhc",
    lang: "de",
    date: 20160225,
    info: `Berlin-Kreuzberg: Kriminalität am Kottbusser Tor - Nordafrikanische Kriminelle verkaufen vor den Augen der Polizei Drogen und überfallen Passanten`,
  },
  {
    src: "https://www.youtube.com/embed/FviS3GC3x6U",
    id: "FviS3GC3x6U",
    lang: "de",
    date: 20090508,
    info: `Drogenhearing Kotti - Wohin mit den Junkies?`,
  },
  {
    src: "https://www.youtube.com/embed/kvtV5_KYVtc",
    id: "kvtV5_KYVtc",
    lang: "tr",
    date: 20180409,
    info: `MEDYABERLİN- Burak akbaş'ın faili mehcul çinayete kurban olmasının üzerinden 6 yıl geçti. Sosyal Danışman Ercan Yaşaroğlu   Burak Akbaş anıt açılışı ve gündemi değerlendirdi.`,
  },
];
