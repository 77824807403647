import React from "react";
import Fade from "react-reveal/Fade";

function Artikel2() {
    return (
        <div className="artikel">
            <Fade top duration={2000}>
                <h2>Kültür Emperyalizmi</h2>
                <div className="text">
                    <p>
                        Avrupa değerleri ve yaşam kültürünün yan ürünü olan
                        Kapitalizm, dünyanın yağmalanmasını klasik sömürgecilik
                        ile sürdüremeyeceğini düşünmeye başladığı andan
                        itibaren, zaten kültüründe var olan farklılıklar
                        üzerinden ötekileştirme özelliğini ön plana çıkararak
                        bütün dünyada kendine işbirlikçiler aramaya başladı. Bu
                        işbirlikçileri küçük benzerliklerle de olsa kendisine
                        yakın hissettiği gruplar içerisinden seçme yoluna gitti.{" "}
                    </p>
                    <h4>Localar</h4>
                    <p>
                        Batı, 1850 yıllarında kıtalarda ve ülkelerde kendileri
                        ile işbirliği yapabilecek işbirlikçileri tespit etme
                        amacıyla farklı farklı "Localarda” toplantılar düzenledi
                        ve bölgelerdeki etkisini artırdı.{" "}
                    </p>
                    <p>
                        Ortaya çıktığı andan itibaren “O Localar"da maddi ve
                        manevi her türlü dayanışma gösterilerek, yaşadıkları
                        ülkelerde söz sahibi yapma ve Avrupa merkezli düşünme
                        öğretisi ile işbirlikçileri şekillendirildi; diğerleri
                        ise ötekileştirild
                    </p>
                    <h4>İlericilik - Değerleri Yok Sayma</h4>
                    <p>
                        Avrupa’da teknolojik gelişmeye dayalı olarak, Avrupa
                        toplumlarının veya kuzey ülkelerinin yaşam tarzı, ileri
                        toplum şekli olarak görülmeye başlandı. Kendileri öyle
                        görüyordu; işbirlikçileri de onlar gibi düşünmeye ve
                        yaşamaya başladılar. İnsanlığın binlerce yıllık ortak
                        değerleri, birikimleri ve yaşam tarzları ile kuşaktan
                        kuşağa aktarılan tüm değerleri, gericilik olarak
                        yaftalanırken, aile içi çocuk eğitimi sayesinde binlerce
                        yıllık değerlerin kuşağa aktarılma zincirinin batı usulü
                        eğitim tarzı ile kopması sağlandı.{" "}
                    </p>
                    <p>
                        Her işgal ettiği ülkede ve kıtada inanç ve kültür
                        misyonerliği yaparken, kendi yaşam tarzını ve
                        değerlerini sömürgeci aileler tarafından buralarda
                        yayabildiğini gördü. Ardından bilinçli bir şekilde bu
                        pratiğini, kendi çekmecelerinin tümünü kapsayan (inanç,
                        milliyetçilik, kültürel değerler ve ideolojiler gibi)
                        değerlerini sözüm ona bilim üzerinden tüm dünyaya yaymak
                        ve özendirmek için kullandı.
                    </p>
                    <p>
                        Ülkelerde ve kıtalarda bulunan farklılıkları ön plana
                        çıkartarak kendilerine en yakın kültür ile işbirliği
                        içerisine girdiler. Bunu hayata geçirebilmeyi de en
                        kolay eğitim üzerinden yaptılar.{" "}
                    </p>
                    <p>
                        18. yüzyılda Asya, Ortadoğu, Afrika ve Latin Amerika’da
                        Avrupa yaşam tarzları ile yaşayan ve onlara özenen
                        insanlar üretmişlerdi.
                    </p>
                    <p>
                        Batı, “kapitalizm” adını verdiği sömürü düzenini
                        kurarken ve bunları hayata geçirirken insanlığa ve
                        tabiata karşı acımasızca davranışlar içerisindeydi.{" "}
                    </p>
                    <p>
                        Kendi menfaatleri doğrultusunda, dünyada -insan
                        toplulukları dahil, hayvanlar aleminde olsun, tabiatta
                        olsun- tüketemeyeceği, yok edemeyeceği hiçbir değerin
                        olmadığını zaman içerisinde herkese gösterdiler.
                    </p>
                    <p>
                        Amerika kıtasında hastalık yayma ve adam başı para
                        vererek cinayetler işletme yoluyla Kızılderililerin yok
                        edilmesi sağlandı. Aynı şekilde Avustralya’daki yerliler
                        de katledilerek veya yaşam alanları işgal edilerek
                        azınlık haline getirilmiştir. Ayrıca, Çin’deki afyon
                        olayı ile halkın, hatta sarayın bile uyuşturucu
                        müptelası haline getirilişinde İngiltere’nin payı
                        unutulmamalıdır.{" "}
                    </p>
                    <p>
                        Okyanuslardaki balinaların soyunun tüketilmesi, Afrika
                        kıtasındaki yerlilerin köleleştirilmesi ve cennet
                        kuşlarının soyunun tüketilmesi gibi buna benzer bir sürü
                        örnekler var.
                    </p>
                    <p>
                        Avrupa kökenliler üç kıtada hâkim hale gelmişti. Diğer
                        kıtaların tümünde varlığını sürdürebilmek için, o
                        ülkelerdeki farklılıkları kullanarak kendi değerlerinin
                        aşısını yapmış oldu.{" "}
                    </p>
                    <p>
                        Kısacası kendi değerlerine yakın benzerlikleri üzerinden
                        işbirlikçilerini buldu ve onlar üzerinden toplumları
                        ayrıştırma yoluna gitti.
                    </p>
                    <p>
                        Oysa o ülkelerde yaşayan insanların kendi aralarındaki
                        farklılıklarının hiç bir önemi yoktu. Çünkü onlar
                        binlerce yıl birlikte yaşamayı öğrenmişlerdi ve
                        farklılıklarını ön plana çıkartmadan yaşamayı hayata
                        geçirebiliyorlardı. Devlet farklı vergi pratiği uygulasa
                        da vatandaşlar arasında farklılıkların etnik köken,
                        inanç ve fiziksel farklılıkların hiç bir önemi yoktu.
                        Komşu, meslektaş, akraba ve vatandaş olarak görüyorlardı
                        birbirilerini. Kız alıp kız verme pratiği yaşanır
                        haldeydi.
                    </p>
                    <h4>Osmanlı Örneği ve Jön Türkler</h4>
                    <p>
                        Osmanlı İmparatorluğunu örnek alalım. 50 farklı kültürü
                        bünyesinde barındıran, dünyada var olan farklı
                        inançların birbiri ile saygı içerisinde yaşayabildiği,
                        dönemindeki tek devlet durumundaydı. Fakat, enerji
                        kaynakları bakımından zengin büyük Osmanlı topraklarının
                        parçalanması gerekiyordu. Tabi ki orada da aynı oyun
                        sahneye koyuldu.{" "}
                    </p>
                    <p>
                        Bir taraftan farklı halk toplulukları inanç, ırk, mezhep
                        üzerinden ayrıştırılıp birbirine düşürülürken, diğer
                        yandan da batıya eğitim almak için gelen genç Osmanlılar
                        ‘batı değerleri’ yalanlarıyla beyinleri yıkanıp
                        ülkelerinin parçalanmasında kullanılmak üzere Osmanlıya
                        geri gönderildiler.{" "}
                    </p>
                    <p>
                        Teknolojik bilginin aktarılması için Fransa’ya
                        gönderilen bu öğrencilerin, süreç içerisinde bilgi
                        yerine Avrupa’nın düşünce ve yaşam tarzını ülkeye
                        getirmeleri ve bunların Osmanlı İmparatorluğu’nun kilit
                        noktalarına yerleştirilmesiyle, saray çevresindeki
                        etkilerinin artmasından dolayı, iktidardakiler ile
                        toplumun bir birinden ayrışması da hızlandı.
                    </p>
                    <p>
                        Tabi ki bu Genç Türkler imparatorluğun parçalanmasından
                        sonra onlar, yeni kurulacak devletçiklerde, batının
                        menfaat ve çıkarlarını koruyup gözetecek yeni idareciler
                        olacaktı.{" "}
                    </p>
                    <p>
                        Eğitimlerini bitirip ülkeye döndüklerinde 4000 Fransızca
                        kelime ve Avrupalının ‘beyefendi’ ve ‘hanımefendi’ gibi
                        hitap şeklini ve ‘merci’ yi de getirdiler beraberlerinde
                        Osmanlıya. Halk ile saray çevresi bir birlerinden farklı
                        iki dil konuşur hale gelmişti. Fransızca bilmek
                        Osmanlıda ilericilik olmuştu ve bunlar "aydın" haline
                        getirilmişti. Bürokrasi dili halk tarafından
                        anlaşılmayan bir dil olmuştu ve bürokratlar kıyafetleri
                        davranışları ile de kendi halkına tamamen
                        farklılaşmıştı.{" "}
                    </p>
                    <p>
                        Artık Jön Türkler Anadolu’nun "Beyaz Türkleri"
                        beyefendileri olmuşlardı. Anadolu insanının
                        mütevazılığını ve duygusallığını kötüye kullanmayı
                        öğrenmişlerdi Avrupa’da. Osmanlıda Avrupa kafası ile
                        düşünmek ve Avrupa yaşam tarzını benimsemek ilericilik
                        olmuştu. Şalvarı çıkartıp pantolon giyen ‘modern’di
                        artık. Akabinde, içerisinden çıktıkları halktan kopuş
                        ile kendi kültürüne yabancılaşma yaşadılar.{" "}
                    </p>
                    <p>
                        Peki kimdi bunlar? Anadolu’nun bağrından hiç bir genç
                        Avrupa’ya eğitime gidemedi. Gidenler paşa torunları veya
                        zengin çocuklarıydı, Avrupa’daki kültüre kendilerini
                        daha yakın hissedenlerin çocuklarıydılar. Müşavirlerin,
                        bürokratların, Hıristiyanların ve saray paşalarının
                        çevresiydi.{" "}
                    </p>
                    <p>
                        Aynı dönemde Anadolu insanı, İttihat ve Terakki
                        cemiyetinde baş gösteren Fransız veya Almanya
                        mandalarını kabul etme kavgalarına şahit oldu.
                    </p>
                    <p>
                        Avrupa’dan getirdikleri yeni düşüncelerle, milliyetçilik
                        (kafatasçılık), inanç farklılıkları, ırk ve ırkçılık
                        üzerine bilimsel tezler üreten bilim adamları türemişti
                        İstanbul’da.
                    </p>
                    <p>
                        Anadolu’nun İNSAN olma değerlerinin tümünü sorgulamaya,
                        insanların gurur ve onurları ile oynamaya başlamışlardı
                        aynı Avrupa küstahlığı ile.{" "}
                    </p>
                    <p>
                        Batı, kendi barbarlıklarını, tabiata karşı ve insanlığa
                        karşı işlemiş oldukları suçları gizleyerek, modern
                        toplum, sivilizasyon, hümanizm, demokrasi lakırtıları
                        ile bütün dünyayı kandırdıkları gibi onları da
                        kandırabilmişti.{" "}
                    </p>
                    <h4>Cumhuriyet</h4>
                    <p>
                        Cumhuriyetin kuruluş döneminde de bu Avrupa değerleri
                        üst düzeyde Anadolu’ya sokuldu. Beyaz Türkler, bireysel
                        özgürlükleri kısıtladı. Kılık/kıyafet, inanç, kültürel
                        çeşitlilik gibi unsurlar “modernizm" sözcüğü ile baskı
                        altına alındı.
                    </p>
                    <p>
                        Ülkenin doktorları, avukatları, profesörleri okumuş
                        diktatörler oldular ve ülke insanını kendilerine
                        yalakalık yapar hale getirdiler. Yukarıda da yazdığım
                        gibi bunlar Anadolu’nun bağrından kopmuş Anadolu
                        çocukları değillerdi.{" "}
                    </p>
                    <p>
                        Anadolu insanının bir gecede okuma yazma özürlü hale
                        getirilmesi ile Avrupa’da tahsil görmüşlerinin iktidarı
                        kurulmuş oldu. Okuma yazma oranlarını her yıl
                        açıklayarak, insanımızın özgüvenini aşağıya çekmişler,
                        geri kalmış ülke kompleksi vererek vatandaşlarda Avrupa
                        özentilerini artırmışlardır.
                    </p>
                    <p>
                        Şekilcilik ve değer taşıma ile Avrupalı olma çabası
                        hızlı bir şekilde geliştirildi. Bu arada laiklik kavramı
                        üzerinden Anadolu insanını ötekileştirecek yeni
                        sözcüklerde üretildi; “gerici, cahil” gibi.{" "}
                    </p>
                    <p>
                        Laiklik veya laik olmak Avrupalıdan daha Avrupalı olma
                        yalakalığıdır. Laikliğin hayat bulduğu bilinç Anadolu’da
                        zaten vardı. Bu kavram daha çok gayrimüslim çevrelerde
                        taraftar bulmuştu.{" "}
                    </p>
                    <p>
                        1960 -1970 -1980 Darbeleri ile ülkenin ‘insan gibi insan
                        olma’ bilincinin yerine, egoist, ben merkezli, saygısız,
                        (insan olma ve onun tabiatı ile değerlerine karşı) bir
                        anlayış üretilmiş oldu.{" "}
                    </p>
                    <p>
                        İnsanın ve ileri toplum değerlendirilmesinin ‘insani
                        değerler’ üzerinden olması gerekirken, Avrupalının buhar
                        makinesi, otomobilleri, teknolojik gelişmişlikleri,
                        toplum değerleri ‘ilericilik’ olarak bizlere aktarıldı.{" "}
                    </p>
                    <p>
                        Avrupalı filozoflar gibi düşünüp dünyayı onlar gibi
                        yorumlarken, Avrupalı/ABD’li "BATI" merkezli düşünür
                        olduk. Dünyanın farklı ülkelerinin değer çeşitliliğinin
                        zenginlik olduğu bize unutturuldu.
                    </p>
                    <p>
                        Tabiat ve insanlığa karşı işlenen suçları bile, suni
                        yaratılmış KAPİTALİZİM tartışmalarıyla ve kapitalizmin
                        değerleri üzerinden tartışır hale geldik.
                    </p>
                    <p>
                        Batılıyı ve değerlerini sorgulayacağımıza, kapitalizmin
                        anayurdunun Batı olduğunu sorgulayacağımıza, önümüze
                        konan sözcükleri tartışır ve olayın özüne inemez olduk.{" "}
                    </p>
                    <h4>Hitler</h4>
                    <p>
                        Oysa sonrasında bütün dünyanın şahit olacağı üzere,
                        demokratik seçim ile iktidara gelmiş Hitler iktidarı da
                        (Almanya insan katletmek için fabrika kurduğunda),
                        paylaşım hırsı ile 70 milyon insanın ölümüne neden olan
                        değerler de Avrupa değerleriydi. Bizim ülkemizdeki
                        Avrupa merkezli düşünenler de o dönemde de Hitler
                        yanlısı veya Mussolini yanlısı yayınlar yapmışlardı.
                        Cumhuriyet Gazetesi, o dönemde gamalı haçı kapak yapıp
                        bizim ülkemizde Almanya teknolojisinin reklamını
                        yapmıştır. Mussolini’nin Libya’da ve Kuzey Afrika’daki
                        katliamlarının haber değeri bile olmamış; Ömer Muhtar’ın
                        İtalyan faşistlerine karşı direnişi de İslamcı ve gerici
                        açıklamaları ile geçiştirilmişti. Bu ötekileştirmeleri
                        de işin bir başka yönünün başlangıcıydı.
                    </p>
                    <h4>Kültürel - Edebi Emperyalizm</h4>
                    <p>
                        Ülke ve insan sevgisiyle dolu fedakâr, erdemli,
                        özverili, sevgi yumağı insanlarımızı da kaybettik. Batı,
                        müziği ile de, duygusallığımızı kaybetmemize yardımcı
                        oldu.{" "}
                    </p>
                    <p>
                        Batının film endüstrisi bizim yakışıklılık ve güzellik
                        anlayışlarımızı da değiştirdi. Kadının ve erkeğin
                        sarışınının beğenilmesi gibi.{" "}
                    </p>
                    <p>
                        Suni olarak yarattıkları kahramanların filmlerini tekrar
                        tekrar izleyerek, o kahramanların isimlerini ve
                        hikâyelerini ezberler olduk, "Aslan Yürekli Richard",
                        Robin Hood, Süpermen, vs.. Edebiyatta batılı yazarların
                        düşüncelerini ülke insanlarına uygulamaya çalıştık.{" "}
                    </p>
                    <p>
                        Kendi realitemizden koparak, ülke insanı için sıfatlar
                        üreterek onların ötekileştirilmesinde başrol oyuncusu
                        haline getirildik. Ülke insanları kendi realitelerinden
                        ve ülkesinden utanır hale getirildi.
                    </p>
                    <p>
                        Ülkemizin insanları duygusaldır ve ’insanlığının’ nedeni
                        duygusallığından kaynaklanır. Bizler bu duygusallığı
                        kuşaktan kuşağa aktaramazsak ülkedeki insanlığı
                        kaybederiz. Bir daha yerine koyma olanağı olmayan bir
                        kopuş sağlanmış olunur.{" "}
                    </p>
                    <p>
                        Tekrar öz değerlerinden ilham alan ülke insanın
                        yaratılması için çaba sarf edilmesi, eğitimle, toplumsal
                        eğitimle ve aile içi eğitimiyle bu duyarlılığın
                        kazanılması gerekir. Paylaşımcı, dayanışmacı, bireysel
                        özgürlüğüne düşkün ama toplumsal da düşünebilen kuşaklar
                        yaratmamız gerekir.{" "}
                    </p>
                    <h4>Dünyanın Kültür Sayesinde Sömürülmesi</h4>
                    <p>
                        Kapitalizm kavramı üzerinden hayat bulan küresel sömürü
                        düzeni, her anlamda inanlığı, kültürleri, farklı yaşam
                        alanlarını, tabiatı kısacası bütün dünyayı boğan bir
                        mekanizma geliştirdi. Aynı oyun, aynı şablonda bütün
                        dünyada oynandı. 1860 ‘lardan sonra Avrupa
                        emperyalizminin geliştirdiği ‘kültür emperyalizmi’
                        kavramı, Ortadoğu, Çin, Güney Kore ve Latin Amerika’da
                        da sözüm ona yine ‘ilericeler’ ile hayata geçirildi.
                    </p>
                    <p>
                        Asya’da Hıristiyanlığa ilk inananlar, kültürel açıdan
                        etkilenenler olmuştur. İşbirlikçileri sayesinde Batı,
                        dünyayı daha az masrafla yağmalamayı sürdürebildi.{" "}
                    </p>
                    <p>
                        Latin Amerika’daki klasik sömürgecilik döneminde
                        yerleşmiş baskının ve yapılan katliamların
                        sorumlularının torunları iktidarlarını sürdürmeye devam
                        etti ve kültür emperyalizminin işbirlikçileri oldular.
                        Onların iktidarda kalması için de batı elinden gelen her
                        şeyi yaptı ve yapıyor.{" "}
                    </p>
                    <p>
                        Anadolu’da yaratılan kendi kimliğinden utanan insan
                        tipolojisi Latin Amerika’da da yaratılmış oldu. Afrika
                        üzerine düşünecek olur isek, her şeyi ile 200 yıl
                        öncesine kadar her anlamda sömürülmüş ve yağmalanmıştır.
                        Bugün bile durum değişmemiştir.{" "}
                    </p>
                    <p>
                        Milyonlarca insanın açlıktan öldüğü bir kıtada insan
                        realitesi de her türlü ırkçılığa maruz kalmıştır. Bu
                        realitenin dünyanın her kıtasında yaşam bulması
                        sağlanmış oldu. Avrupa, kibrini ve küstahlığını, bütün
                        dünyaya o ülkelerdeki işbirlikçileri üzerinden taşıdı.{" "}
                    </p>
                    <p>
                        Küstahlığı, kibri ayıplayan toplumlar, bu süreçte kendi
                        değer dünyası içerisinde Avrupa merkezli düşünmeyi
                        öğrenmiş oldu. Bu ülkelerin insanları veya dünya
                        insanlığı da Avrupa’ya kendini kanıtlamak için şekilci
                        olarak ‘Avrupa yalakalığına’ başlamış oldu.{" "}
                    </p>
                    <h4>Gelinen Genel Durum - Toplum</h4>
                    <p>
                        Genel anlamda bugün insanlığın 12 bin yıllık kuşaktan
                        kuşağa aktarılan iyi insan eğitimi bitmiş ve yerini
                        egoizm almıştır. Erdemlilik, sevgi, saygı, onurlu duruş,
                        gibi kavramların yanında kötü ve iyi insanın tarifi bile
                        değişti.
                    </p>
                    <p>
                        Toplumsal eğitim, aile içi eğitim, akrabalık eğitimi ve
                        sosyal kontrol mekanizmasına yapılan müdahalelerle,
                        tamamen farklı ve kendi değerlerinden kopmuş bir toplum
                        ortaya çıkarıldı.{" "}
                    </p>
                    <p>
                        Bencillik öyle aşılandı ki bugünkü kuşaklarda ve
                        toplumda, “ben kadın sorumluluğu alamam, ben bir erkeğin
                        sorumluluğunu taşımam, çocuk istemiyorum, hayatımı
                        yaşayacağım” diyen erkek ve kadınlar oluştu.
                    </p>
                    <p>
                        Egoist erkek ve kadınlarının bu istemlerini batı
                        ülkeleri bugün PARA vererek kırma yoluna gidiyor; nüfus
                        azalmasına para ile önlem almaya çalışıyor.{" "}
                    </p>
                    <p>
                        Buna paralel olarak ekonomik olarak gücü olmayan
                        ülkelerde doğum kontrolü programlarını da yine aynı
                        yollarla hayata geçiriyorlar. Bizlere doğum kontrolü
                        önerileri yapar iken, kendi ülkelerinde çocuk doğumunu
                        teşvik ediyor ve bu doğrultuda yardım üstüne yardım
                        yapıyorlar.
                    </p>
                    <h4>Neler Yapılmalı?</h4>
                    <p>
                        Aile içi eğitimi ve toplumsal eğitimi ön plana
                        çıkararak, dolandırıcıyı, hırsızı, madde satıcısını, iş
                        bitirenini, kazık atanı ve sözüm ona mafya bozuntularını
                        ayıplayan, dışlayan bir toplum yaratıldığında,
                        dedelerimizden gelen değerleri gelecek kuşaklara
                        taşıyabiliriz.
                    </p>
                    <p>
                        Vatandaş sözcüğünün içini doldurarak, farklıklarımız
                        yerine ortak yönlerimizi konuşarak, "BİZ" kültürünü
                        geliştirmemiz gerekir.
                    </p>
                    <p>
                        Anadolu realitesi veya "İpek Yolu" yaşam tarzı tekrar
                        dünyanın gündemini belirlemeli. O yüzden, bugüne kadar
                        var olan Avrupa merkeziyetçi düşünce tarzından çıkarak
                        farklı düşünmeyi öğrenmemiz gerekir.{" "}
                    </p>
                    <p>
                        Dünyanın geleceği, dünyanın realitesini kavramaktan
                        geçiyor.{" "}
                    </p>
                    <p>
                        İnsan olma değerlerinin geçmişte yapılmış olan tarifinin
                        bugün için güncelleştirilmesi ve ilericiliğin yeni
                        tanımının bu değerler üzerinden yapılması gerekecektir.
                        İnsanlık ister istemez gelecekte bu yeni ilerici düşünce
                        tarzını benimseyecektir. Dünya, tabiattaki diğer
                        canlılar ve bütün insanlık için.
                    </p>
                    <p>Ercan Yaşaroğlu</p>
                </div>
            </Fade>
        </div>
    );
}

export default Artikel2;
